import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Empresa } from 'models/empresa/empresa';
import empresaService from 'services/empresa.service';
import { RootState } from 'store/store';

export interface ParametroState {
  empresa?: Empresa;
}

const initialState: ParametroState = {
  empresa: undefined,
};

const parametroSlice = createSlice({
  name: 'parametro',
  initialState,
  reducers: {
    setParametro: (state, action: PayloadAction<ParametroState>): void => {
      state.empresa = action.payload.empresa;
    },
  },
});

export const { setParametro } = parametroSlice.actions;

export const obterParametro = createAsyncThunk(
  'parametro/obterParametro',
  async (args, thunkAPI): Promise<void> => {
    const state = thunkAPI.getState() as RootState;
    const parametro = !state.parametro.empresa;

    if (parametro) {
      const parametrizacao = await empresaService.obterEmpresa();
      thunkAPI.dispatch(setParametro({ empresa: parametrizacao }));
    }
  }
);

export const parametroSelector = (state: RootState): ParametroState =>
  state.parametro;

export default parametroSlice.reducer;
