import classnames from 'classnames';
import UserMenu from 'components/Shared/UserMenu/UserMenu';
import { TipoProjeto } from 'models/enums/TipoProjeto';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import LayoutRoutes from 'routes/layout.routes';

import { LogoEscuro, LogoTelmac } from 'shared/icons';
import { authSelector, logout } from 'store/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { hostNameSelector, obterHostName } from 'store/hostName/hostName.slice';
import {
  atualizarModuloExterno,
  moduloExterno,
} from 'store/sessao/sessao.slice';
import styles from './AuthNavbar.module.scss';

const getLogo = (hostname: string): string => {
  switch (hostname) {
    case TipoProjeto.MinhaRech:
      return LogoEscuro;

    case TipoProjeto.MinhaTelmac:
      return LogoTelmac;

    default:
      return LogoEscuro;
  }
};

const AuthNavbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { usuarioLogado } = useAppSelector(authSelector);
  const { hostName } = useAppSelector(hostNameSelector);
  const history = useHistory();
  const isModuloExterno = useAppSelector(moduloExterno);
  const enviarParaLogin = (): void => {
    if (isModuloExterno) {
      dispatch(atualizarModuloExterno());
    } else {
      dispatch(logout()).then((res) => {
        history.push('/auth/home');
      });
    }
  };

  const [path, setPath] = React.useState<string>('');

  useEffect(() => {
    dispatch(obterHostName());
  }, []);

  useEffect(() => {
    if (path !== history.location.pathname) {
      history.listen((location) => {
        setPath(location.pathname);
      });
    }
  }, [history.location.pathname]);

  return (
    <Navbar
      className={classnames(
        'navbar-top navbar-horizontal navbar-dark pt-md-2',
        styles.AuthNavbar
      )}
      data-testid="AuthNavbar"
    >
      <Container
        className={classnames(
          'px-4   d-flex',
          usuarioLogado
            ? 'justify-content-between'
            : 'justify-content-center justify-content-md-between'
        )}
      >
        <NavbarBrand onClick={enviarParaLogin}>
          <img
            alt="..."
            src={getLogo(hostName!)}
            className={classnames(`icon-${hostName}`, {
              [`icon-rech-termosUso`]:
                window.location.pathname === LayoutRoutes.auth.termosPolitica,
            })}
          />
        </NavbarBrand>

        {usuarioLogado && <UserMenu />}
      </Container>

      {path === LayoutRoutes.auth.termosPolitica && (
        <div className={classnames(`linha-gradiente__${hostName}`)} />
      )}
    </Navbar>
  );
};

export default React.memo(AuthNavbar);
