import { Empresa } from 'models/empresa/empresa';
import BaseService from './base.service';

class EmpresaService extends BaseService<Empresa> {
  constructor() {
    super('empresa');
  }

  obterEmpresa = (): Promise<Empresa> => {
    return super.obterPorRota<Empresa>('');
  };
}

export default new EmpresaService();
