import { AxiosResponse } from 'axios';
import { api } from 'communication/axiosConfig';

// A mock function to mimic making an async request for data
export function fetchCount(amount = 1): Promise<{ data: number }> {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}

class CounterService {
  getUsers = (): Promise<AxiosResponse<any>> => api.get('users');
}

export default new CounterService();
