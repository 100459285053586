import { UsuarioState } from 'store/usuario/usuario.slice';
import BaseService from './base.service';

class UsuarioService extends BaseService<UsuarioState> {
  constructor() {
    super('usuario');
  }
}

export default new UsuarioService();
