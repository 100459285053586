import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LayoutRoutes from 'routes/layout.routes';
import { authSelector } from 'store/auth/auth.slice';
import { useAppSelector } from 'store/hooks';
import { sessaoSelector } from 'store/sessao/sessao.slice';

export const useSessao = (): void => {
  const { usuarioLogado } = useAppSelector(authSelector);
  const { sessaoCarregada } = useAppSelector(sessaoSelector);
  const history = useHistory();

  useEffect(() => {
    if (!usuarioLogado && !sessaoCarregada) {
      history.push(LayoutRoutes.auth.root);
    }
  }, [history, sessaoCarregada, usuarioLogado]);
};
