import classnames from 'classnames';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectModal } from 'store/modal/modal.slice';
import CustomModal, {
  CustomModalProps,
} from '../../../Core/CustomModal/CustomModal';
import styles from './ContainerModal.module.scss';

interface CustomContainerProps {
  className?: string;
}

const ContainerModal: React.FC<CustomContainerProps> = (Containerprops) => {
  const customModal: CustomModalProps = useAppSelector(selectModal);
  const { className } = Containerprops;

  return (
    <div
      data-testid="ContainerModal"
      className={classnames(styles.CustomContainer, className)}
    >
      {customModal && <CustomModal {...customModal} />}
    </div>
  );
};

export default React.memo(ContainerModal);
