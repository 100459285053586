import MaskHelper from 'helpers/mask.helper';
import Credenciais from 'models/auth/Credenciais';
import { EnumTipoLogin } from 'models/enums/TipoLogin';
import TokenEncode from 'models/sessao/TokenEncode';
import BaseService from './base.service';

class AuthService extends BaseService<TokenEncode> {
  constructor() {
    super('autenticacao');
  }

  private removerMascara = (usuario: any, tipoUsuario?: number): string => {
    return MaskHelper.removerMascaraCpfCnpj(
      usuario,
      tipoUsuario === 20 ? EnumTipoLogin.Cliente : EnumTipoLogin.Colaborador
    );
  };

  autenticar = (credenciais: Credenciais): Promise<TokenEncode> => {
    credenciais.usuario = this.removerMascara(
      credenciais.usuario,
      credenciais.tipoUsuario
    );

    return super.post<TokenEncode>(undefined, credenciais);
  };
}

export default new AuthService();
