import React from 'react';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { logout } from 'store/auth/auth.slice';
import { sessaoSelector } from 'store/sessao/sessao.slice';
import { obterPrimeiraPalavra } from 'helpers/manipulacao.helper';

import { useHistory } from 'react-router-dom';
import LayoutRoutes from 'routes/layout.routes';
import styles from './UserMenu.module.scss';

const UserMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { tokenSessao } = useAppSelector(sessaoSelector);
  const history = useHistory();
  const primeiraLetra = tokenSessao?.nome.charAt(0);
  const primeiroNome = tokenSessao && obterPrimeiraPalavra(tokenSessao.nome);

  const handleLogout = (): void => {
    dispatch(logout());
    history.push(LayoutRoutes.auth.login);
  };
  return (
    <UncontrolledDropdown
      nav
      className={styles.UserMenu}
      data-testid="UserMenu"
    >
      <DropdownToggle className="pr-0" nav>
        <Media className="align-items-center">
          <span className="avatar avatar-sm rounded-circle usuario-portrait">
            {primeiraLetra}
          </span>
          <Media className="ml-2 d-none d-lg-block text-light ">
            <span className="mb-0 text-sm font-weight-bold text-gray800">
              {tokenSessao?.nome}
            </span>
          </Media>
        </Media>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem className="noti-title" header tag="div">
          <h6 className="text-overflow m-0">
            {t('menu:bem-vindo', {
              name: primeiroNome,
            })}
          </h6>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem key="user-menu-sair" onClick={handleLogout}>
          <i className="fas fa-sign-in-alt" />
          <span>{t('termos.sair')}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default React.memo(UserMenu);
