class Modulo {
  constructor(
    public readonly nome: string,
    public readonly descricao: string,
    public readonly identificador: string,
    public readonly path: any,
    public readonly route: any
  ) {}
}

const HOSTNAME: { [key: string]: string } = {
  minharech: 'Rech',
  minhatelmac: 'Telmac',
};

const getHostName = (): string => {
  let hostName: string;
  const hasDash = window.location.hostname.includes('-');

  if (hasDash) {
    hostName = HOSTNAME[window.location.hostname.split('-')[0]];
  } else {
    hostName = HOSTNAME[window.location.hostname.split('.')[0]];
  }

  return hostName ?? '';
};

export default {
  produto: new Modulo(
    'DESENVOLVIMENTO DE NOVOS PRODUTOS',
    'Inclui os processos de análise, desenvolvimento e acompanhamento de produtos',
    'desenvolvimento-produto',
    '/dnp',
    '/dnp/home'
  ),
  catalogo: new Modulo(
    'CATÁLOGO DIGITAL',
    'Único catálogo multimarcas do mercado. Uma ferramenta mais rápida, com busca inteligente que possibilita gerar listas de orçamento.',
    'catalogo',
    '/catalogo',
    '/catalogo/home'
  ),
  portal: new Modulo(
    'PORTAL DE CLIENTES',
    'Um portal de serviços e comunicação com os clientes',
    'portal-cliente',
    '/portal',
    '/portal/home'
  ),
  recomendacao: new Modulo(
    'RECOMENDAÇÃO DE PRODUTOS',
    'Aumente suas vendas com nosso robô de recomendações de produtos.',
    'recomendacao',
    '/recomendacao',
    '/recomendacao/home'
  ),
  bancoFoto: new Modulo(
    'BANCO DE FOTOS',
    'Aumente suas vendas, consultando a foto dos nossos produtos, são mais de 15 mil fotos em um único local.',
    'banco-foto',
    '/banco-foto',
    '/banco-foto/'
  ),
  excessoEstoque: new Modulo(
    'EXCESSOS DE ESTOQUE',
    'Confira aqui a lista de produtos de curva A e B em excesso de estoque de sua linha, aproveite as oportunidades para alavancar suas vendas.',
    'excesso-estoque',
    '/auth/excesso-estoque',
    '/auth/excesso-estoque'
  ),
  painelControle: new Modulo(
    'PAINEL DE CONTROLE',
    `Módulo responsável por permitir configurar as parametrizações do Sistema Minha ${getHostName()} e seus módulos`,
    'painel-controle',
    '/painel-controle',
    '/painel-controle/home'
  ),
};
