import LoaderBar from 'components/LoaderBar/LoaderBar';
import React, { lazy, Suspense } from 'react';

const LoginPageLazy = lazy(() => import('./LoginPage'));

const defaultProps = { children: '' };

const LoginPage = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
): JSX.Element => (
  <Suspense fallback={<LoaderBar />}>
    <LoginPageLazy {...props} />
  </Suspense>
);

LoginPage.defaultProps = defaultProps;

export default LoginPage;
