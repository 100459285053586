import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Switch, Redirect } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import CustomFooter from 'components/Shared/CustomFooter/CustomFooter';
import AuthNavbar from 'components/Auth/AuthNavbar/AuthNavbar';
import LayoutRoutes from 'routes/layout.routes';
import RoutesHelper from 'helpers/routes.helper';
import CustomLabel, {
  TypeLabel,
} from 'components/Core/CustomLabel/CustomLabel';
import { useAppSelector } from 'store/hooks';
import { authSelector } from 'store/auth/auth.slice';
import {
  moduloExterno,
  quantidadeModuloSelector,
  sessaoSelector,
} from 'store/sessao/sessao.slice';
import { obterPrimeiraPalavra } from 'helpers/manipulacao.helper';
import classNames from 'classnames';
import { hostNameSelector } from 'store/hostName/hostName.slice';
import { buscarEmpresa } from 'helpers/validacao.helper';
import { useMobile } from 'hooks';
import { useHeader } from 'hooks/Header.hook';
import styles from './Auth.module.scss';

const Auth: any = () => {
  const mobile = useMobile();
  const isMobile = mobile.is();
  const mainContent = React.useRef(null);

  const location = useLocation();
  const { t } = useTranslation();
  const { generateHeader } = useHeader();
  const { usuarioLogado } = useAppSelector(authSelector);
  const { tokenSessao } = useAppSelector(sessaoSelector);
  const primeiroNome = tokenSessao && obterPrimeiraPalavra(tokenSessao.nome);
  const { hostName } = useAppSelector(hostNameSelector);
  const quantidadeDeModulos = useAppSelector(quantidadeModuloSelector);
  const isModuloExterno = useAppSelector(moduloExterno);
  const nenhumModuloAtivo = quantidadeDeModulos === 0 || undefined;
  const hostNamePrimeiraLetraMaiuscula = hostName
    ? hostName.charAt(0).toUpperCase() + hostName.slice(1)
    : '';

  const updateTitleNameAndFavIcon = useCallback((): void => {
    const favicon: any = document.getElementById('favicon') ?? {};
    const hostNames = buscarEmpresa();

    if (hostNames.includes('telmac')) {
      document.title = 'Minha Telmac';
      favicon.href = 'favicontelmac.ico';
    } else {
      document.title = 'Minha Rech';
      favicon.href = 'favicon.ico';
    }
  }, []);

  useEffect(() => {
    updateTitleNameAndFavIcon();
  }, [updateTitleNameAndFavIcon]);

  useEffect(() => {
    let classAdded: string;
    if (location.pathname !== LayoutRoutes.auth.termosPolitica) {
      classAdded = 'bg-light-custom';
    } else {
      classAdded = 'bg-white';
    }

    document.body.classList.add(classAdded);
    return () => {
      document.body.classList.remove(classAdded);
    };
  }, [window.location.pathname]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [location]);

  return (
    <>
      <div
        className={classNames('main-content', {
          'bg-white': location.pathname === LayoutRoutes.auth.termosPolitica,
        })}
        ref={mainContent}
      >
        <AuthNavbar />
        <div
          className={classNames(styles.Auth, 'header', {
            'pt-5 bg-gradient-light-custom':
              location.pathname !== LayoutRoutes.auth.termosPolitica,
            'pb-0 bg-white':
              location.pathname === LayoutRoutes.auth.termosPolitica,
          })}
        >
          <Container>
            <Row className={classNames(styles.Auth_Header)}>
              <CustomLabel
                testId="saudacoes"
                type={
                  // eslint-disable-next-line no-nested-ternary
                  isMobile
                    ? TypeLabel.h4
                    : usuarioLogado
                    ? TypeLabel.h2
                    : TypeLabel.h1
                }
                bold
                className={classNames('text-dark', {
                  'd-none':
                    location.pathname === LayoutRoutes.auth.termosPolitica,
                })}
                style={{
                  position: 'relative',
                }}
              >
                {generateHeader(location.pathname)}
              </CustomLabel>
            </Row>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className={classNames('fill-light-custom', {
                  'd-none':
                    location.pathname === LayoutRoutes.auth.termosPolitica,
                })}
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container>
          <Row className={classNames(styles.Auth_Container)}>
            <Switch>
              <Redirect exact from="/" to={LayoutRoutes.auth.login} />
              {RoutesHelper.getRoutes(LayoutRoutes.auth.layout)}
              <Redirect from="*" to={LayoutRoutes.auth.login} />
            </Switch>
          </Row>
        </Container>
      </div>
      <CustomFooter />
    </>
  );
};

export default Auth;
