const layout = '/auth';

const auth = {
  layout,
  root: `${layout}/login`,
  login: `${layout}/login`,
  register: `${layout}/register`,
  recuperarSenha: `${layout}/esqueci-senha`,
  escolhaModulo: `${layout}/escolha-modulo`,
  primeiroAcesso: `${layout}/ativar-cadastro`,
  alterarSenha: `${layout}/alterar-senha/:tipo/:hash`,
  trocarSenha: `${layout}/trocar-senha`,
  termosPolitica: `${layout}/termos-politica`,
  excessoEstoque: `${layout}/excesso-estoque`,
};

export default auth;
