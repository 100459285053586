import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buscarEmpresa } from 'helpers/validacao.helper';
import { RootState } from 'store/store';

export interface HostNameState {
  hostName?: string;
}

const initialState: HostNameState = {
  hostName: undefined,
};

const hostNameSlice = createSlice({
  name: 'hostName',
  initialState,
  reducers: {
    setHostName: (state, action: PayloadAction<string>): void => {
      state.hostName = action.payload;
    },
  },
});

export const { setHostName } = hostNameSlice.actions;

export const obterHostName = createAsyncThunk(
  'hostName/obterHostName',
  async (arg, thunkAPI): Promise<void> => {
    const state = thunkAPI.getState() as RootState;
    const hostName = !state.hostName.hostName;

    if (hostName) {
      const hostNameLogado = buscarEmpresa();

      thunkAPI.dispatch(setHostName(hostNameLogado));
    }
  }
);

export const hostNameSelector = (state: RootState): HostNameState =>
  state.hostName;

export default hostNameSlice.reducer;
