import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface Props {
  exact?: boolean;
  url: string;
  path: string;
  sensitive?: boolean;
  strict?: boolean;
}

const defaultProps = {
  exact: false,
  sensitive: false,
  strict: false,
};

const ExternalRedirect: React.FC<Props> = (props: Props) => {
  const { path, url, ...routeProps } = props;

  return (
    <div data-testid="ExternalRedirect">
      <Route
        {...routeProps}
        render={() => {
          window.location.replace(url);
          return null;
        }}
      />
      <Redirect from="/" to={path} />
    </div>
  );
};

ExternalRedirect.defaultProps = defaultProps;

export default React.memo(ExternalRedirect);
