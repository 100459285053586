import LoaderBar from 'components/LoaderBar/LoaderBar';
import React, { lazy, Suspense } from 'react';

const ExcessoEstoquePageLazy = lazy(() => import('./ExcessoEstoquePage'));

const defaultProps = { children: '' };

const ExcessoEstoquePage = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
): JSX.Element => (
  <Suspense fallback={<LoaderBar />}>
    <ExcessoEstoquePageLazy {...props} />
  </Suspense>
);

ExcessoEstoquePage.defaultProps = defaultProps;

export default ExcessoEstoquePage;
