class RegexHelper {
  static obterPrimeiroMatch = (valor: string, rgx: RegExp): string | null => {
    const valorMatch = valor.match(rgx);
    if (valorMatch) {
      return valorMatch[0];
    }
    return null;
  };
}

export default RegexHelper;
