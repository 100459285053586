import moment from 'moment';
import i18n from 'locales/i18n';

export const unixTimeStampToDate = (secondsSinceUnixEpoch: number): Date => {
  const date = new Date(0);
  date.setUTCSeconds(secondsSinceUnixEpoch);
  return date;
};

export const capitalize = (value: string): any => {
  return value.charAt(0).toUpperCase() + value.substring(1);
};

export const formatDate = (
  value: string | Date | undefined,
  format: string
): any => {
  if (value) {
    return capitalize(moment(value).format(format));
  }
  return value;
};

export const transformDate = (
  value: string | Date | undefined,
  format: string,
  formatOutput: string
): any => {
  if (!value) {
    return null;
  }
  return capitalize(moment(value, format).format(formatOutput));
};

export const customFormatDate = (value: string | Date, format: string): any => {
  const hoje = i18n.t('dates:hoje');
  const ontem = i18n.t('dates:ontem');
  const date = moment(value).calendar().split(' ')[0];

  if (date === hoje || date === ontem) {
    return capitalize(moment(value).calendar().split(' ')[0]);
  }
  return capitalize(moment(value).format(format));
};

export const convertToNumber = (value: string | undefined): number => {
  value = value?.toString();

  if (!value || value === '') {
    return 0;
  }

  return parseFloat(value?.replaceAll('.', '').replaceAll(',', '.'));
};

export const convertToStringDecimal = (
  value: string | undefined,
  fixed: number
): string => {
  return convertToNumber(value).toFixed(fixed);
};

export const formatarMoeda = (
  value: number | undefined
): string | undefined => {
  return value?.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const transformarPorcentagemDuasCasasDecimais = (
  value: number | undefined
): string => {
  if (!value) {
    value = 0;
  }

  return `${value?.toFixed(2)}%`;
};

export const transformarPorcentagemSemCasasDecimais = (
  value: number | undefined
): string => {
  if (!value) {
    value = 0;
  }

  return `${value?.toFixed(0)}%`;
};

export const obterPrimeiraPalavra = (value: string): any => {
  return value.split(' ', 1);
};

export const megaByteToByte = (value: number): number => {
  return value * 1024 * 1024;
};

export const validarTamanhoTotalArquivos = (
  arquivos: File[],
  tamanhoMaximo: number
): boolean => {
  const tamanhoTotalPermitido = megaByteToByte(tamanhoMaximo);

  const tamanhoTotal = arquivos.reduce((a, b) => a + b.size, 0);

  return tamanhoTotal <= tamanhoTotalPermitido;
};
