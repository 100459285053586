import Modulo from 'models/modulo/ModuloSessao';
import LocalStorageService from './localStorage.service';

const CHAVE_SESSAO = 'nsr_modulo';

class ModuloService {
  estaAtivoModulo = (): boolean => {
    return LocalStorageService.existe(CHAVE_SESSAO);
  };

  obterModulo = (): Modulo => {
    const modulo = LocalStorageService.obter(CHAVE_SESSAO);
    return modulo;
  };

  salvarModulo = (modulo: Modulo): void => {
    LocalStorageService.salvar(CHAVE_SESSAO, modulo);
  };

  removerModulo = (): void => {
    LocalStorageService.remover(CHAVE_SESSAO);
  };
}

export default new ModuloService();
