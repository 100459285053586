import global from 'locales/pt-BR/shared/global.json';
import footer from 'locales/pt-BR/shared/footer.json';
import notificacao from 'locales/pt-BR/shared/notificacao.json';
import dates from 'locales/pt-BR/shared/dates.json';
import menu from 'locales/pt-BR/shared/menu.json';
import departamentoEmail from 'locales/pt-BR/shared/departamento-email.json';
import home from 'locales/pt-BR/shared/home.json';
import ajustePermissionamento from 'locales/pt-BR/shared/ajuste-permissionamento.json';

export default {
  global,
  footer,
  notificacao,
  dates,
  menu,
  departamentoEmail,
  home,
  ajustePermissionamento,
};
