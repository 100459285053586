import React, { useRef } from 'react';
import classNames from 'classnames';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { useAppSelector } from 'store/hooks';
import { hostNameSelector } from 'store/hostName/hostName.slice';
import styles from './LoaderBar.module.scss';

export interface LoaderBarProps {
  className?: string;
  color?: string;
  shadow?: boolean;
  isLoading?: boolean;
}

const LoaderBar: React.FC<LoaderBarProps> = (props) => {
  const { hostName } = useAppSelector(hostNameSelector);
  const { color, shadow, className, isLoading } = props ?? {};
  const defaultColor = hostName === 'telmac' ? '#141F39' : '#2677F9';
  const ref = useRef<LoadingBarRef>(null);

  React.useEffect(() => {
    if (isLoading) {
      ref.current?.continuousStart();
    }
  }, [isLoading]);

  return (
    <div data-testid="LoaderBar">
      <LoadingBar
        className={classNames(styles.LoaderBar, className)}
        color={color || defaultColor}
        shadow={shadow}
        ref={ref}
        onLoaderFinished={ref.current?.complete}
      />
    </div>
  );
};

export default React.memo(LoaderBar);
