import React, { useEffect } from 'react';
import ContainerToast from 'components/Shared/Toast/ContainerToast/ContainerToast';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectLoading } from 'store/loader/loader.slice';
import { authSelector, logout } from 'store/auth/auth.slice';
import {
  moduloExterno,
  moduloSelector,
  recarregarModulo,
  recarregarSessao,
  sessaoSelector,
  verificarModulos,
} from 'store/sessao/sessao.slice';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useAxiosLoader } from 'communication/axiosConfig';
import { inicializarGoogleAnalytics } from 'helpers/analytics.helper';
import AuthLayout from 'layouts/Auth';
import LayoutRoutes from 'routes/layout.routes';
import ContainerModal from 'components/Shared/Modal/ContainerModal/ContainerModal';
import SessaoService from 'services/sessao.service';
import Modulo from 'shared/modulos';
import styles from 'scss/App.module.scss';
import moduloService from 'services/modulo.service';
import ExternalRedirect from 'components/Shared/Redirect/ExternalRedirect';
import {
  obterParametro,
  parametroSelector,
} from 'store/parametro/parametro.slice';
import LoaderBar from 'components/LoaderBar/LoaderBar';

const App: React.FC = () => {
  const [loading] = useAxiosLoader();
  const { usuarioLogado } = useAppSelector(authSelector);
  const usuario = SessaoService.estaLogadoValidarSessao();
  const modulo = moduloService.estaAtivoModulo();
  const { sessaoCarregada } = useAppSelector(sessaoSelector);
  const moduloIdentificador = useAppSelector(moduloSelector);
  const isModuloExterno = useAppSelector(moduloExterno);
  const { empresa } = useAppSelector(parametroSelector);
  const dispatch = useAppDispatch();
  const removeLoaderIndex = (): void => {
    const divLoaderIndex = document.getElementById('divLoaderIndex');
    if (divLoaderIndex) {
      divLoaderIndex.classList.add('available');
      setTimeout(() => {
        if (divLoaderIndex.parentNode) {
          divLoaderIndex.outerHTML = '';
        }
      }, 2000);
    }
  };

  const obterModuloEscolhido = (): JSX.Element => {
    switch (moduloIdentificador) {
      case Modulo.produto.identificador:
        return (
          <>
            <ExternalRedirect
              exact
              path={Modulo.produto.path}
              url={`${window.location.origin}${Modulo.produto.route}`}
            />
          </>
        );
      case Modulo.catalogo.identificador:
        return (
          <>
            <ExternalRedirect
              exact
              path={Modulo.catalogo.path}
              url={`${window.location.origin}${Modulo.catalogo.route}`}
            />
          </>
        );
      case Modulo.portal.identificador:
        return (
          <>
            <ExternalRedirect
              exact
              path={Modulo.portal.path}
              url={`${window.location.origin}${Modulo.portal.route}`}
            />
          </>
        );
      case Modulo.recomendacao.identificador:
        return (
          <>
            <ExternalRedirect
              exact
              path={Modulo.recomendacao.path}
              url={`${window.location.origin}${Modulo.recomendacao.route}`}
            />
          </>
        );
      case Modulo.bancoFoto.identificador:
        return (
          <>
            <ExternalRedirect
              exact
              path={Modulo.bancoFoto.path}
              url={`${window.location.origin}${Modulo.bancoFoto.route}`}
            />
          </>
        );
      case Modulo.excessoEstoque.identificador:
        return (
          <>
            <Route
              path={LayoutRoutes.auth.layout}
              render={(props) => <AuthLayout {...props} />}
            />
            <Redirect from="/" to={LayoutRoutes.auth.excessoEstoque} exact />
          </>
        );
      case Modulo.painelControle.identificador:
        return (
          <>
            <ExternalRedirect
              exact
              path={Modulo.painelControle.path}
              url={`${window.location.origin}${Modulo.painelControle.route}`}
            />
          </>
        );
      default:
        return (
          <>
            {usuario && !modulo && (
              <>
                <Route
                  path={LayoutRoutes.auth.layout}
                  render={(props) => <AuthLayout {...props} />}
                />
                <Redirect from="/" to={LayoutRoutes.auth.escolhaModulo} exact />
              </>
            )}
          </>
        );
    }
  };

  React.useEffect(() => {
    if (usuarioLogado && usuario) {
      dispatch(recarregarSessao());
      dispatch(verificarModulos());
      dispatch(recarregarModulo());
    }
    if (usuarioLogado && !usuario) {
      dispatch(logout());
    }
    dispatch(obterParametro());
    removeLoaderIndex();
  }, [dispatch, usuario, usuarioLogado]);

  useEffect(() => {
    inicializarGoogleAnalytics(empresa);
  }, [empresa]);

  return (
    <div className={styles.App} data-testid="App">
      <Router basename={process.env.PUBLIC_URL}>
        {(useAppSelector(selectLoading) || loading) && (
          <LoaderBar isLoading={loading} />
        )}
        <div className="App-content">
          <Switch>
            {(!sessaoCarregada && !usuarioLogado) ||
            (sessaoCarregada && usuarioLogado && isModuloExterno) ||
            (sessaoCarregada && !usuarioLogado && isModuloExterno) ? (
              <>
                <Route render={(props) => <AuthLayout {...props} />} />
              </>
            ) : (
              <>{obterModuloEscolhido()}</>
            )}
          </Switch>
          <ContainerToast />
          <ContainerModal />
        </div>
      </Router>
    </div>
  );
};

export default App;
