import Acesso, {
  IEsqueciSenhaOutput,
  ISenhaAlteradaOutput,
  ITrocarSenhaInput,
} from '../models/acesso/Acesso';
import BaseService from './base.service';

class AcessoService extends BaseService<Acesso> {
  constructor() {
    super('acesso');
  }

  alterarSenha = (acessoCredenciais: Acesso): Promise<ISenhaAlteradaOutput> => {
    const retorno = super.put<any>('senha/alterarporhash', acessoCredenciais);
    return retorno;
  };

  trocarSenha = (
    acessoCredenciais: ITrocarSenhaInput
  ): Promise<ISenhaAlteradaOutput> => {
    const retorno = super.put<any>('senha/trocar', acessoCredenciais);
    return retorno;
  };

  esqueciSenha = ({
    cpfCnpj,
  }: {
    cpfCnpj: string;
  }): Promise<IEsqueciSenhaOutput> => {
    const retorno = super.put<any>('senha/esqueci', { cpfCnpj });
    return retorno;
  };

  aceitarTermos = (token: string): Promise<any> => {
    const retorno = super.put<any>(
      'termos/aceitar',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return retorno;
  };
}

export default new AcessoService();
