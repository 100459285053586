import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import FiltroMenu from 'models/filtro/FiltroMenu';
import FiltroResponse from 'models/filtro/FiltroResponse';
import Permissionamento from 'models/Permissionamento';
import GrupoPermissao from 'models/Shared/GrupoPermissao';
import GrupoPermissaoService from 'services/grupoPermissao.service';
import { limparModulo } from 'store/sessao/sessao.slice';
import { RootState } from 'store/store';

export interface GrupoPermissaoState {
  gruposPermissoes: GrupoPermissao[];
}

const initialState: GrupoPermissaoState = {
  gruposPermissoes: [],
};

const grupoPermissaoSlice = createSlice({
  name: 'grupoPermissao',
  initialState,
  reducers: {
    setGrupoPermissoes: (
      state,
      action: PayloadAction<GrupoPermissao[]>
    ): void => {
      state.gruposPermissoes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(limparModulo, (state) => {
      state.gruposPermissoes = initialState.gruposPermissoes;
    });
  },
});

export const { setGrupoPermissoes } = grupoPermissaoSlice.actions;

export const carregarGruposPermissao = createAsyncThunk(
  'grupoPermissao/carregarGruposPermissao',
  async (arg: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const projetoListasAuxiliaresVazias =
      state.gruposPermissao.gruposPermissoes.length === 0;

    if (projetoListasAuxiliaresVazias) {
      GrupoPermissaoService.listar(arg).then((response) => {
        thunkAPI.dispatch(setGrupoPermissoes(response));
      });
    }
  }
);

export const filtrarPermissoesGrupoPorId = createAsyncThunk(
  'grupoPermissao/filtrarPermissoesGrupoPorId',
  async ({
    idGrupo,
    filtro,
  }: {
    idGrupo: number;
    filtro: FiltroMenu;
  }): Promise<FiltroResponse<Permissionamento>> => {
    const projetos = GrupoPermissaoService.listarPermissoesGrupoPorId(
      idGrupo,
      filtro
    );
    return projetos;
  }
);

export const salvarPermissoesGrupo = createAsyncThunk(
  'grupoPermissao/salvarPermissoesGrupo',
  async ({
    idGrupo,
    permissoes,
  }: {
    idGrupo: number;
    permissoes: Permissionamento[];
  }): Promise<void> => {
    return GrupoPermissaoService.salvarPermissoesGrupo(idGrupo, permissoes);
  }
);
export const selectGruposPermissoes = (state: RootState): GrupoPermissao[] =>
  state.gruposPermissao.gruposPermissoes;

export default grupoPermissaoSlice.reducer;
