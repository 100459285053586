import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { enviarEventoAnalytics } from 'helpers/analytics.helper';
import {
  IEsqueciSenhaOutput,
  ISenhaAlteradaOutput,
  ITrocarSenhaInput,
} from 'models/acesso/Acesso';
import {
  EventoAcao,
  EventoCategoria,
  EventoOrigem,
} from 'models/enums/EventoAnalytics';
import acessoService from 'services/acesso.service';
import { RootState } from 'store/store';

export interface AcessoState {
  hash?: string;
  tipo?: string;
  senha?: string;
}

export enum TipoAcesso {
  Redefinir = 'redefinir',
  Ativar = 'ativar',
}

const initialState: AcessoState = {
  hash: undefined,
  senha: undefined,
};

const acessoSlice = createSlice({
  name: 'acesso',
  initialState,
  reducers: {},
});

export const alterarSenha = createAsyncThunk(
  'acesso/alterarSenha',
  async (acessoCredenciais: AcessoState): Promise<ISenhaAlteradaOutput> => {
    const retorno = await acessoService.alterarSenha(acessoCredenciais);

    if (retorno) {
      if (acessoCredenciais.tipo === TipoAcesso.Ativar) {
        enviarEventoAnalytics(
          EventoCategoria.AtivacaoCadastroEfetuado,
          EventoAcao.AtivadoCadastro,
          `Acesso via: ${EventoOrigem.TelaAlterarSenha}`
        );
      }
      if (acessoCredenciais.tipo === TipoAcesso.Redefinir) {
        enviarEventoAnalytics(
          EventoCategoria.RecuperacaoSenhaEfetuada,
          EventoAcao.RecuperarSenhaEfetuada,
          `Acesso via: ${EventoOrigem.TelaAlterarSenha}`
        );
      }
    }
    return retorno;
  }
);

export const trocarSenha = createAsyncThunk(
  'acesso/trocarSenha',
  async (
    acessoCredenciais: ITrocarSenhaInput
  ): Promise<ISenhaAlteradaOutput> => {
    const retorno = await acessoService.trocarSenha(acessoCredenciais);
    if (retorno) {
      enviarEventoAnalytics(
        EventoCategoria.TrocaSenhaEfetuada,
        EventoAcao.TrocarSenha,
        `Acesso via: ${EventoOrigem.TelaAlterarSenha}`
      );
    }
    return retorno;
  }
);

export const esqueciSenha = createAsyncThunk(
  'acesso/esqueciSenha',
  async ({ cpfCnpj }: { cpfCnpj: string }): Promise<IEsqueciSenhaOutput> => {
    const retorno = await acessoService.esqueciSenha({ cpfCnpj });
    return retorno;
  }
);

export const aceitarTermos = createAsyncThunk(
  'acesso/aceitarTermos',
  async (tokenAcesso: string) => {
    const retorno = await acessoService.aceitarTermos(tokenAcesso);

    return retorno;
  }
);

export const acessoSelector = (state: RootState): AcessoState => state.acesso;

export default acessoSlice.reducer;
