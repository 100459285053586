export enum EnumTipoLogin {
  Cliente,
  Colaborador,
}

export interface InterfaceTipoLogin {
  chave?: EnumTipoLogin;
  valor?: 10 | 20;
}

class TipoLogin {
  private static retornoTipoLogin: InterfaceTipoLogin;

  static getTipoLogin(tipoLogin: EnumTipoLogin): InterfaceTipoLogin {
    switch (tipoLogin) {
      case EnumTipoLogin.Cliente:
        this.retornoTipoLogin = { chave: tipoLogin, valor: 20 };
        break;
      case EnumTipoLogin.Colaborador:
        this.retornoTipoLogin = { chave: tipoLogin, valor: 10 };
        break;
      default:
        this.retornoTipoLogin = { chave: undefined, valor: undefined };
    }

    return this.retornoTipoLogin;
  }

  static getMensagemErro(tipoLogin: EnumTipoLogin): string {
    switch (tipoLogin) {
      case EnumTipoLogin.Cliente:
        return 'Seu CPF/CNPJ e/ou senha estão incorretos.';
      case EnumTipoLogin.Colaborador:
        return 'Seu usuário, e-mail e/ou senha estão incorretos.';
      default:
        return '';
    }
  }
}

export default TipoLogin;
