import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export interface LoaderState {
  isLoading: boolean;
}

const initialState: LoaderState = {
  isLoading: false,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { startLoading, stopLoading } = loaderSlice.actions;

export const selectLoading = (state: RootState): boolean =>
  state.loader.isLoading;

export default loaderSlice.reducer;
