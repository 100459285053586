import { RotaTemplate } from 'models/RotaTemplate';
import React from 'react';
import { Route } from 'react-router-dom';
import routes from 'routes/layout.config';
import RegexHelper from './regex.helper';

const regexParametro = /:(\w+)/g;
const regexNumero = /(\d+)/g;
const substitutoParametro = 'XXXX';

const rotasAjustadas: RotaTemplate[] = routes.map((x) => {
  const parametroKey = RegexHelper.obterPrimeiroMatch(x.path, regexParametro);

  if (parametroKey) {
    return {
      ...x,
      path: x.path.replace(parametroKey, substitutoParametro),
    };
  }
  return { ...x, path: x.path };
});

class RoutesHelper {
  static getRoutesByLayout = (layout: string): any => {
    return routes.filter((x) => x.layout === layout);
  };

  static getRoutes = (layout: string): any => {
    const layoutRoutes = RoutesHelper.getRoutesByLayout(layout);

    return layoutRoutes.map(
      (prop: any, key: React.Key | undefined | undefined) => {
        return (
          <Route path={prop.path} component={prop.component} key={key} exact />
        );
      }
    );
  };

  static obterRotaPai = (pathname: string): RotaTemplate | undefined => {
    if (pathname) {
      const path = pathname.slice(0, pathname.lastIndexOf('/'));

      if (rotasAjustadas.find((x) => x.layout === path)) {
        return undefined;
      }

      const numeroMatch = RegexHelper.obterPrimeiroMatch(pathname, regexNumero);
      const parentRoute = RoutesHelper.obterInformacaoRota(path);

      if (parentRoute) {
        return {
          ...parentRoute,
          path: parentRoute.path.replace(
            substitutoParametro,
            numeroMatch || ''
          ),
        };
      }
    }
    return undefined;
  };

  static obterRotasPai = (pathname: string): RotaTemplate[] | undefined => {
    const rotas: RotaTemplate[] = [];
    let path: string | null = pathname;

    while (path) {
      const rota = RoutesHelper.obterRotaPai(path);
      if (rota) {
        rotas.push(rota);
        path = rota.path;
      } else {
        path = null;
      }
    }
    return rotas.reverse();
  };

  static obterInformacaoRota = (pathname: string): RotaTemplate | undefined => {
    const path = pathname.replace(regexNumero, substitutoParametro);

    if (path) {
      const route = rotasAjustadas.find((x) => (x as any).path === path);
      if (!route) {
        return RoutesHelper.obterRotaPai(path);
      }
      return route;
    }
    return undefined;
  };

  static montarRota = (
    pathname: string,
    parametros: string | number | any | undefined
  ): string => {
    if (parametros) {
      if (parametros.constructor === Object) {
        const caminho = pathname.replace(
          regexParametro,
          (propFull: string, prop: string): string => {
            return parametros[prop]?.toString();
          }
        );

        return caminho;
      }

      const parametroKey = RegexHelper.obterPrimeiroMatch(
        pathname,
        regexParametro
      );

      if (parametroKey) {
        return pathname.replace(parametroKey, parametros);
      }
    }
    return pathname;
  };
}

export default RoutesHelper;
