import moment from 'moment';
import 'moment/locale/pt-br';

moment.updateLocale('pt-br', {
  months:
    'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split(
      '_'
    ),
  weekdaysMin: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
});
