/* eslint-disable no-underscore-dangle */
import { api } from 'communication/axiosConfig';
import ToastHelper from 'helpers/toast.helper';
import i18n from 'locales/i18n';
import { store } from 'store/store';
import SessaoService from 'services/sessao.service';
import ModuloService from 'services/modulo.service';

export const mostrarErro = (err: any): void => {
  let mensagemErro = err.message;
  console.log(err);

  if (err.response && err.response.data.mensagens) {
    [mensagemErro] = err.response.data.mensagens;
    ToastHelper.Erro(mensagemErro, i18n.t('erros.titulo-toast'));
  } else {
    ToastHelper.ErroGenerico();
  }
};

const setupInterceptors = (): void => {
  api.interceptors.request.use(
    (config) => {
      const tokenAcesso = store.getState().sessao.tokenSessao?.tokenAcesso;
      config.headers.clienteId = 0;
      if (tokenAcesso) {
        config.headers.Authorization = `Bearer ${tokenAcesso}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== 'autenticacao') {
        // Token de acesso expirado
        if (
          err.response &&
          err.response.status === 401 &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;

          try {
            await ModuloService.removerModulo();
            await SessaoService.removerSessao();
            return api(originalConfig);
          } catch (_error) {
            // mostrarErro(originalConfig.url, _error);
            return Promise.reject(_error);
          }
        }
        if (!originalConfig.url.includes('tipoErro=campo')) {
          mostrarErro(err);
        }

        if (!err.response) {
          ToastHelper.ErroGenerico();
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setupInterceptors;
