import classnames from 'classnames';
import CustomButton from 'components/Core/CustomButton/CustomButton';
import CustomLabel, {
  TypeLabel,
} from 'components/Core/CustomLabel/CustomLabel';
import React, { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { ModalTitle } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from 'reactstrap';
import { VariantColor } from 'shared/VariantColor';
import { closeModal } from 'store/modal/modal.slice';
import styles from './CustomModal.module.scss';

export enum TypeModal {
  Default,
  Success,
  Danger,
}

export interface CustomModalProps extends ModalProps {
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  timeout?: number;
  subtitulo?: string | undefined;
  titulo?: string | undefined;
  tituloHeader?: string | undefined;
  type?: TypeModal;
  onOkClick?: () => void;
  onCancelClick?: () => void;
  textoOk?: string | undefined;
  textoCancel?: string | undefined;
  esconderBotaoCancel?: boolean;
  manterAbertaOnOkClick?: boolean;
  classNameCancelButton?: string;
  disableBotaoOk?: boolean;
}

interface Template {
  icone?: JSX.Element | null;
  colorOkButton?: VariantColor;
  classNameCancelButton?: string;
  className?: string;
}

const CustomModal: React.FC<CustomModalProps> = (modalProps) => {
  const {
    type,
    subtitulo,
    titulo,
    tituloHeader,
    children,
    className,
    setIsOpen,
    timeout,
    onOkClick,
    onCancelClick,
    textoOk,
    textoCancel,
    esconderBotaoCancel,
    manterAbertaOnOkClick,
    classNameCancelButton,
    disableBotaoOk,
    ...res
  } = modalProps;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const onDismissModal = useCallback((): void => {
    dispatch(closeModal());
    if (setIsOpen) {
      setIsOpen(false);
    }
  }, [dispatch, setIsOpen]);

  useEffect(() => {
    if (res.isOpen && timeout) {
      const timeId = setTimeout(() => {
        onDismissModal();
      }, timeout);

      return () => {
        clearTimeout(timeId);
      };
    }

    return undefined;
  }, [onDismissModal, res.isOpen, timeout]);

  function getTemplate(): Template {
    switch (type) {
      case TypeModal.Success:
        return {
          icone: (
            <i className="far fa-check-circle fa-4x mb-3 text-success d-flex justify-content-center" />
          ),
          colorOkButton: VariantColor.Success,
          classNameCancelButton: 'text-dark',
        };

      case TypeModal.Danger:
        return {
          icone: (
            <i className="fas fa-exclamation-circle fa-4x mb-3 d-flex justify-content-center" />
          ),
          colorOkButton: VariantColor.Light,
          classNameCancelButton: 'text-white',
          className: 'modal-danger',
        };

      default:
        return {
          classNameCancelButton: classNameCancelButton || 'text-dark',
        };
    }
  }

  const template = getTemplate();

  return (
    <Modal
      data-testid="CustomModal"
      {...res}
      className={classnames(
        'modal-dialog-centered',
        template.className,
        styles.CustomModal,
        className
      )}
      toggle={onDismissModal}
    >
      <ModalHeader
        toggle={onDismissModal}
        className="py-1 px-3 align-items-center"
      >
        {tituloHeader}
      </ModalHeader>

      {subtitulo && (
        <ModalTitle className="px-3 pb-2 font-weight-light">
          {subtitulo}
        </ModalTitle>
      )}
      <ModalBody className="px-3">
        {template.icone}
        {template.icone && (
          <CustomLabel type={TypeLabel.h2} bold className="modal-title">
            {titulo}
          </CustomLabel>
        )}
        {children}
      </ModalBody>
      <ModalFooter>
        {!esconderBotaoCancel && (
          <CustomButton
            color={VariantColor.Link}
            className={template.classNameCancelButton}
            onClick={() => {
              if (onCancelClick) onCancelClick();
              onDismissModal();
            }}
          >
            {textoCancel ?? t('termos.cancelar')}
          </CustomButton>
        )}
        <CustomButton
          color={template.colorOkButton}
          onClick={() => {
            if (onOkClick) onOkClick();
            if (!manterAbertaOnOkClick) onDismissModal();
          }}
          className="ml-auto"
          disabled={disableBotaoOk}
        >
          {textoOk ?? t('termos.ok')}
        </CustomButton>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(CustomModal);
