/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  buscaTipoUsuario,
  enviarEventoAnalytics,
} from 'helpers/analytics.helper';
import Credenciais from 'models/auth/Credenciais';
import { EventoAcao, EventoCategoria } from 'models/enums/EventoAnalytics';
import i18n from 'locales/i18n';
import AuthService from 'services/auth.service';
import SessaoService from 'services/sessao.service';
import usuarioService from 'services/usuario.service';
import {
  atualizarSessao,
  limparSessao,
  limparSessaoTokenExpirado,
} from 'store/sessao/sessao.slice';
import { RootState } from 'store/store';
import { setUsuario, UsuarioState } from 'store/usuario/usuario.slice';

export interface AuthState {
  usuarioLogado: boolean;
  isError: boolean;
  errorMessage: string | undefined;
}

const initialState: AuthState = {
  usuarioLogado: SessaoService.estaLogadoValidarSessao(),
  isError: false,
  errorMessage: '',
};

export const autenticar = createAsyncThunk(
  'auth/autenticar',
  async (credenciais: Credenciais, thunkAPI): Promise<any> => {
    try {
      const usuarioExterno = credenciais.tipoUsuario === 20;
      const token = await AuthService.autenticar(credenciais);

      sessionStorage.setItem('token', JSON.stringify(token));
      sessionStorage.setItem('credenciais', JSON.stringify(credenciais));

      if (token) {
        // caso seja usuario externo, passa pela valida;áo do termo
        if (usuarioExterno) {
          // retorna os dados do usuario diretamento com o bearer tokem "chumbado", ja que o usuario ainda nao esta logado
          const usuarioInfo = await usuarioService
            .get<UsuarioState>('informacao', {
              headers: {
                Authorization: `Bearer ${token.tokenAcesso}`,
                clienteId: 0,
              },
            })
            .then((res) => {
              return res;
            });
          // caso usuario ja tenha o ultimo termo aceite, prossegue com o login padrao
          if (usuarioInfo.politicaPrivacidadeAceita) {
            thunkAPI.dispatch(
              atualizarSessao({
                token,
                lembrarSessao: credenciais.lembrarSessao,
              })
            );
            enviarEventoAnalytics(
              EventoCategoria.Login,
              EventoAcao.Redirecionar,
              buscaTipoUsuario(credenciais.tipoUsuario)
            );
            return token;
          }
          // caso usuario nao tenha o ultimo termo aceitado, a gente seta uma variavel global como falsa, que vai ser escutada na pagina Login pelo useEffect, e caso essa variavel esteja como false, a gente joga para tela do termo
          thunkAPI.dispatch(setUsuario(false));
          // caso seja usuario colaborador,  prossegue com o login padrao
        } else {
          thunkAPI.dispatch(
            atualizarSessao({ token, lembrarSessao: credenciais.lembrarSessao })
          );
          enviarEventoAnalytics(
            EventoCategoria.Login,
            EventoAcao.Redirecionar,
            buscaTipoUsuario(credenciais.tipoUsuario)
          );
          return token;
        }
      }

      return thunkAPI.rejectWithValue(i18n.t('login:erro'));
    } catch (e: any) {
      thunkAPI.dispatch(limparSessao());

      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const atualizarAutenticacao = createAsyncThunk(
  'auth/atualizarAutenticacao',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { lembrarSessao, tokenSessao } = state.sessao;

    await thunkAPI.dispatch(
      autenticar({
        tokenAtualizacao: tokenSessao?.tokenAtualizacao,
        lembrarSessao,
      })
    );
  }
);

export const logout = createAsyncThunk('auth/logout', async (arg, thunkAPI) => {
  thunkAPI.dispatch(limparSessao());
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(autenticar.fulfilled, (state) => {
      state.usuarioLogado = true;
    });
    builder.addCase(autenticar.pending, (state) => {
      state.isError = initialState.isError;
      state.errorMessage = initialState.errorMessage;
    });
    builder.addCase(
      autenticar.rejected,
      (state, action: PayloadAction<any>) => {
        state.usuarioLogado = false;
        state.isError = true;
        if (action.payload.response && action.payload.response.data.mensagens) {
          [state.errorMessage] = action.payload.response.data.mensagens;
        } else {
          state.errorMessage = action.payload.message;
        }
      }
    );

    builder.addCase(logout.fulfilled, (state) => {
      state.usuarioLogado = false;
    });
    builder.addCase(limparSessaoTokenExpirado, (state) => {
      state.usuarioLogado = false;
    });
  },
});

export const authSelector = (state: RootState): AuthState => state.auth;

export default authSlice.reducer;
