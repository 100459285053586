import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterSlice from 'store/counter/counterSlice';
import loaderSlice from 'store/loader/loader.slice';
import toasterSlice from 'store/toaster/toaster.slice';
import authSlice from 'store/auth/auth.slice';
import sessaoSlice from './sessao/sessao.slice';
import modalSlice from './modal/modal.slice';
import grupoPermissaoSlice from './grupoPermissao/grupoPermissao.slice';
import hostNameSlice from './hostName/hostName.slice';
import parametroSlice from './parametro/parametro.slice';
import acessoSlice from './acesso/acesso.slice';
import usuarioSlice from './usuario/usuario.slice';

export const store = configureStore({
  reducer: {
    counter: counterSlice,
    loader: loaderSlice,
    toaster: toasterSlice,
    auth: authSlice,
    sessao: sessaoSlice,
    modal: modalSlice,
    gruposPermissao: grupoPermissaoSlice,
    hostName: hostNameSlice,
    parametro: parametroSlice,
    acesso: acessoSlice,
    usuario: usuarioSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
