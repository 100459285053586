import { FC } from 'react';

export class RotaTemplate {
  public constructor(
    public path: string,
    public name: string,
    public layout: string,
    public component?: FC,
    public breadcrumb?: boolean
  ) {}
}
