/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosRequestConfig } from 'axios';
import customAxios, { api } from 'communication/axiosConfig';
import configEnv from 'config.env';
import { downloadFile } from 'helpers/file.helper';
import FiltroResponse from 'models/filtro/FiltroResponse';
import { Retorno } from 'models/Retorno';
import queryString from 'query-string';

class BaseService<Type> {
  rotaRaiz: string;

  urlRaiz: string;

  constructor(rotaRaiz: string, urlRaiz: string = configEnv.apiUrl) {
    this.rotaRaiz = rotaRaiz;
    this.urlRaiz = urlRaiz;
  }

  montaRota(rota: string | undefined): string {
    if (rota) {
      return `${this.rotaRaiz}/${rota}`;
    }
    return this.rotaRaiz;
  }

  customGet<T>(
    rota?: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      customAxios(this.urlRaiz)
        .get(this.montaRota(rota), config)
        .then((response: any) => {
          return resolve(response.data);
        })
        .catch((error: any) => reject(error));
    });
  }

  customObterPorRota<T>(rota?: string, parametros?: any): Promise<T> {
    if (parametros) {
      const queryStrings = queryString.stringify(parametros);

      return this.customGet(`${rota}?${queryStrings}`);
    }
    return this.customGet(rota);
  }

  get<T>(rota?: string, config?: AxiosRequestConfig | undefined): Promise<T> {
    return new Promise((resolve, reject) => {
      api
        .get(this.montaRota(rota), config)
        .then((response: any) => {
          return resolve(response.data.dados);
        })
        .catch((error: any) => reject(error));
    });
  }

  getFile<T>(
    rota?: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      api
        .get(this.montaRota(rota), config)
        .then((response: any) => {
          downloadFile(response);
          return resolve(response.data.dados);
        })
        .catch((error: any) => reject(error));
    });
  }

  getRetorno<T>(
    rota?: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<Retorno<T>> {
    return new Promise((resolve, reject) => {
      api
        .get(this.montaRota(rota), config)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => {
          let erro = error.response;
          const retorno = new Retorno();

          if (erro) {
            erro = error.response.data;
          } else if (error.message) {
            erro = retorno.retornoComFalha(error.message);
          } else if (error) {
            erro = retorno.retornoComFalha(JSON.stringify(error));
          }

          return reject(erro);
        });
    });
  }

  customPost<T>(
    rota?: string,
    data?: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      customAxios(this.urlRaiz)
        .post(this.montaRota(rota), data, config)
        .then((response: any) => resolve(response.data))
        .catch((error: any) => reject(error));
    });
  }

  post<T>(
    rota?: string,
    data?: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      api
        .post(this.montaRota(rota), data, config)
        .then((response: any) => resolve(response.data.dados))
        .catch((error: any) => reject(error));
    });
  }

  postRetorno<T>(
    rota?: string,
    data?: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      api
        .post(this.montaRota(rota), data, config)
        .then((response: any) => resolve(response.data.dados))
        .catch((error: any) => {
          let erro = error.response;
          const retorno = new Retorno();

          if (erro) {
            erro = error.response.data;
            resolve(erro);
          } else if (error.message) {
            erro = retorno.retornoComFalha(error.message);
          } else if (error) {
            erro = retorno.retornoComFalha(JSON.stringify(error));
          }
          reject(erro);
        });
    });
  }

  put<T>(
    rota?: string,
    data?: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      api
        .put(this.montaRota(rota), data, config)
        .then((response: any) => resolve(response.data.dados))
        .catch((error: any) => reject(error));
    });
  }

  patch<T>(
    rota?: string,
    data?: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      api
        .patch(this.montaRota(rota), data, config)
        .then((response: any) => resolve(response.data.dados))
        .catch((error: any) => reject(error));
    });
  }

  delete<T>(
    rota?: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      api
        .delete(this.montaRota(rota), config)
        .then((response: any) => resolve(response.data.dados))
        .catch((error: any) => reject(error));
    });
  }

  // Métodos genéricos

  filtrar<T>(rota?: string, texto?: string): Promise<T> {
    return this.get(`${rota}?Filtro=${texto}`);
  }

  obterPorRota<T>(rota?: string, parametros?: any): Promise<T> {
    if (parametros) {
      const queryStrings = queryString.stringify(parametros);

      return this.get(`${rota}?${queryStrings}`);
    }
    return this.get(rota);
  }

  listarPorRota<T>(rota?: string): Promise<T[]> {
    return this.get(rota);
  }

  // Subrotas

  obterSubLista<T>(id: number, rota: string): Promise<T[]> {
    return this.get(`${id}/${rota}`);
  }

  obterPorSubRota<T>(id: number, rota: string): Promise<T> {
    return this.get(`${id}/${rota}`);
  }

  salvarSubRota<T>(id: number, rota: string, data?: unknown): Promise<T> {
    return this.post(`${id}/${rota}`, data);
  }

  salvarRota<T>(rota: string, data?: unknown): Promise<T> {
    return this.postRetorno(`${rota}`, data);
  }

  atualizarSubRota<T>(id: number, rota: string, data?: unknown): Promise<T> {
    return this.put(`${id}/${rota}`, data);
  }

  filtrarPorParametrosSubRota<T>(
    id: number,
    rota: string,
    parametros: any
  ): Promise<FiltroResponse<T>> {
    return this.obterPorRota(`${id}/${rota}`, parametros);
  }

  // Métodos especificos

  listar(parametros?: any): Promise<Type[]> {
    let queryStrings = '';
    if (parametros) {
      queryStrings = `?${queryString.stringify(parametros)}`;
    }
    return this.get(queryStrings);
  }

  obterPorParametros(parametros: any): Promise<Type> {
    const queryStrings = queryString.stringify(parametros);
    return this.get(`?${queryStrings}`);
  }

  obterPorId(id: number): Promise<Type> {
    return this.get(`${id}`);
  }

  obterArquivo(id: number): Promise<Type> {
    return this.getFile(`${id}`, { responseType: 'blob' });
  }

  obterArquivoRota(rota: string): Promise<Type> {
    return this.getFile(rota, { responseType: 'blob' });
  }

  obterArquivoPorRota<T>(rota?: string, parametros?: any): Promise<T> {
    if (parametros) {
      const queryStrings = queryString.stringify(parametros);

      return this.getFile(`${rota}?${queryStrings}`, { responseType: 'blob' });
    }
    return this.getFile(rota, { responseType: 'blob' });
  }

  cadastrar(data: Type): Promise<number> {
    return this.post('', data);
  }

  atualizar(id: string, data: Type): Promise<number> {
    return this.put(id, data);
  }

  salvar(data: Type): Promise<number> {
    return (data as any).id
      ? this.atualizar((data as any).id, data)
      : this.cadastrar(data);
  }

  excluir(id: number): Promise<number> {
    return this.delete(`${id}`);
  }

  filtrarPorTexto(texto: string): Promise<Type[]> {
    return this.filtrar('', texto);
  }

  filtrarPor(parametros: any, rota = ''): Promise<FiltroResponse<Type>> {
    return this.obterPorRota(rota, parametros);
  }

  filtrarInputPor(parametros: any): Promise<Retorno<Type>> {
    const queryStrings = queryString.stringify(parametros);

    return this.getRetorno(`?tipoErro=campo&${queryStrings}`);
  }
}

export default BaseService;
