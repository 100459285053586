import { TipoDocumentoCliente } from 'models/enums/TipoDocumentoCliente';
import { EnumTipoLogin } from 'models/enums/TipoLogin';
import { TipoMascara } from 'models/enums/TipoMascara';
import { TipoNumeroBase } from 'models/enums/TipoNumeroBase';
import moment, { Moment } from 'moment';

// 000.000.000-00
export const maskCPF = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

// 00.000.000/0000-00
export const maskCNPJ = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};

// (00) 00000-0000
export const maskPhone = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2');
};

// 00000-000
export const maskCEP = (value: string): string => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');
};

// 00/00/0000
export const maskDate = (
  value: Moment | string | Date,
  dateFormat?: string | boolean | undefined
): string => {
  const dateFormatType = !dateFormat
    ? 'DD/MM/YYYY'
    : dateFormat && dateFormat.toString();
  if (moment.isMoment(value)) {
    return value.format(dateFormatType);
  }
  if (value instanceof Date || (moment(value).isValid() && value.length > 10)) {
    return moment(value).format(dateFormatType);
  }
  if (value.length === 4) {
    return value.replace(/(\d{4})(\d)/, '$1');
  }
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1');
};

// Aceita apenas letras
export const maskOnlyLetters = (value: string): string => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '');
};

// Aceita apenas números
export const maskOnlyNumbers = (value: string): string => {
  return value.replace(/\D/g, '');
};

export const maskCurrency = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d)(\d{2})$/, '$1,$2')
    .replace(/(?=(\d{3})+(\D))\B/g, '.');
};

// Verificar como colocar variavem dentro da expressão regular pra passar as casas decimais por parâmetro
export const maskWeight = (value: string): string => {
  return value.replace(/\D/g, '').replace(/(\d)(\d{4})$/, '$1,$2');
};

export const formatTextByMask = (text: string, mask: TipoMascara): string => {
  let maskedValue = '';

  switch (mask) {
    case TipoMascara.Inteiro:
      maskedValue = maskOnlyNumbers(text);
      break;
    case TipoMascara.Cep:
      maskedValue = maskCEP(text);
      break;
    case TipoMascara.Moeda:
      maskedValue = maskCurrency(text);
      break;
    case TipoMascara.Peso:
      maskedValue = maskWeight(text);
      break;
    default:
      maskedValue = text;
  }

  return maskedValue;
};

const regexNaoDigitos = new RegExp(/\D/g);

class MaskHelper {
  static mascaraCpfCnpj(
    valor: string,
    tipoDocumentoCliente: TipoDocumentoCliente,
    tipoIdentificador: EnumTipoLogin
  ): string {
    const apenasNumeros = /[^0-9]/g;
    const retorno: { [key: string]: string | undefined } = {
      [TipoDocumentoCliente.CPF]: maskCPF(valor),
      [TipoDocumentoCliente.CNPJ]: maskCNPJ(valor),
      [TipoDocumentoCliente.Ambos]:
        ((valor.replaceAll(apenasNumeros, '').length <= TipoNumeroBase.CPF &&
          maskCPF(valor)) as string) ||
        ((valor.replaceAll(apenasNumeros, '').length <= TipoNumeroBase.CNPJ &&
          maskCNPJ(valor)) as string),
    };

    if (tipoIdentificador === EnumTipoLogin.Cliente) {
      return retorno[tipoDocumentoCliente] || valor;
    }

    return valor;
  }

  static removerMascaraCpfCnpj(
    valor: string,
    tipoIdentificador?: EnumTipoLogin
  ): string {
    if (tipoIdentificador === EnumTipoLogin.Cliente) {
      return valor.replace(regexNaoDigitos, '');
    }
    return valor;
  }
}

export default MaskHelper;
