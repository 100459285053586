import auth from 'locales/pt-BR/auth/auth.json';
import login from 'locales/pt-BR/auth/login.json';
import cadastro from 'locales/pt-BR/auth/cadastro.json';
import trocaSenha from 'locales/pt-BR/auth/trocaSenha.json';
import termosPolitica from 'locales/pt-BR/auth/termosPolitica.json';
import excessoEstoque from 'locales/pt-BR/auth/excessoEstoque.json';

export default {
  auth,
  login,
  cadastro,
  trocaSenha,
  termosPolitica,
  excessoEstoque,
};
