import configEnv from 'config.env';
import { Empresa } from 'models/empresa/empresa';
import { TipoAmbienteGA } from 'models/enums/TipoAmbienteGA';
import ReactGa from 'react-ga';
import ReactGA4 from 'react-ga4';

const rastreamentoGA = {
  RECH: {
    DEV: 'UA-232592212-13',
    TST: 'UA-232592212-36',
    HML: 'UA-232592212-14',
    PRD: 'UA-232592212-15',
  },
  TELMAC: {
    DEV: 'UA-232592212-19',
    TST: 'UA-232592212-37',
    HML: 'UA-232592212-20',
    PRD: 'UA-232592212-21',
  },
};

const rastreamentoGA4 = {
  RECH: {
    DEV: 'G-KCXGSZ4PJW',
    TST: 'G-XE61KRFJ02',
    HML: 'G-R0FW5MMW3Q',
    PRD: 'G-72L5NGGE6K',
  },
  TELMAC: {
    DEV: 'G-ETZ213SGR3',
    TST: 'G-E6HVRLNHTN',
    HML: 'G-HFE85N2BH9',
    PRD: 'G-KKYBMP87L8',
  },
};

export const enviarEventoAnalytics = (
  categoria: string,
  acao: string,
  rotulo: string
): void => {
  ReactGa.event({
    category: categoria,
    action: acao,
    label: rotulo,
  });

  ReactGA4.event(categoria, { acao, rotulo });
};

export const buscaTipoUsuario = (tipoUsuario: number | undefined): string => {
  if (tipoUsuario === undefined) {
    return '';
  }
  return tipoUsuario === 10 ? 'Colaborador' : 'Cliente Rech';
};

const obterID = (
  empresa: Empresa,
  ambiente: TipoAmbienteGA,
  versao?: number
): string | undefined => {
  const identificadores = versao === 4 ? rastreamentoGA4 : rastreamentoGA;

  if (empresa?.identificador) {
    const identificadorCampo =
      empresa?.identificador as keyof typeof identificadores;

    return identificadores[identificadorCampo][ambiente];
  }

  return undefined;
};

export const inicializarGoogleAnalytics4 = (
  empresa: Empresa | undefined
): void => {
  if (empresa) {
    const ga4TrackingId = obterID(empresa, configEnv.gaAmbiente, 4);

    ReactGA4.initialize(ga4TrackingId || '', {
      testMode: process.env.NODE_ENV === 'test',
    });
  }
};

export const inicializarGoogleAnalytics = (
  empresa: Empresa | undefined
): void => {
  if (empresa) {
    const gaTrackingId = obterID(empresa, configEnv.gaAmbiente);

    ReactGa.initialize(gaTrackingId || '', {
      testMode: process.env.NODE_ENV === 'test',
    });

    inicializarGoogleAnalytics4(empresa);
  }
};

export const adicionarPageView = (): void => {
  ReactGa.pageview(window.location.pathname + window.location.search);
};
