import { useEffect, useState } from 'react';

export interface IMobileOutput {
  is: () => boolean;
}

const TAMANHO_MOBILE = 767;

export const useMobile = (): IMobileOutput => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const handleWindowSizeChange = (): void => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const is = (): boolean => width <= TAMANHO_MOBILE;

  return { is };
};
