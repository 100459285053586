import { AxiosResponse } from 'axios';

export const downloadFile = (
  response: AxiosResponse<any>,
  type?: string,
  filename?: string
): void => {
  const { data } = response;
  try {
    if (data && (data.size || data.length) > 0) {
      const blob = new Blob([data], {
        type: type || response.headers['content-type'],
      });

      const downloadFileName =
        filename ||
        response.headers['content-disposition']
          .split('filename=')[1]
          .split(';')[0];

      const URL = window.URL || window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');

      downloadLink.download = downloadFileName.replace(/["\s]?/g, '');
      downloadLink.href = downloadUrl;
      downloadLink.click();
      URL.revokeObjectURL(data);
      downloadLink.remove();
    }
  } catch {
    // console.log(e);
  }
};
