import Loader from 'components/Shared/Loader/Loader';
import React, { lazy, Suspense } from 'react';

const TermosEPoliticaPageLazy = lazy(() => import('./TermosEPoliticaPage'));

const defaultProps = { children: '' };

const TermosEPoliticaPage = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
): JSX.Element => (
  <Suspense fallback={<Loader />}>
    <TermosEPoliticaPageLazy {...props} />
  </Suspense>
);

TermosEPoliticaPage.defaultProps = defaultProps;

export default TermosEPoliticaPage;
