// Shared

import shared from 'locales/pt-BR/shared/shared';

// Layouts
import auth from 'locales/pt-BR/auth/auth';
import dnp from 'locales/pt-BR/dnp/dnp';

// Libs
import 'locales/pt-BR/moment';

export default {
  ...shared,
  ...auth,
  ...dnp,
};
