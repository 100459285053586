export class Retorno<Type> {
  public sucesso = true;

  public mensagens: string[] = [];

  public dados?: Type;

  constructor(retorno?: Retorno<Type>) {
    if (retorno) {
      this.sucesso = retorno.sucesso;
      this.mensagens = retorno.mensagens;
      this.dados = retorno.dados;
    }
  }

  retornoComFalha = (mensagem: string): Retorno<Type> => {
    const retorno = new Retorno<Type>();
    retorno.sucesso = false;
    retorno.mensagens = [];
    retorno.mensagens.push(mensagem);

    return retorno;
  };
}
