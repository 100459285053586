import { VariantColor } from 'shared/VariantColor';
import { store } from 'store/store';
import { addToast } from 'store/toaster/toaster.slice';
import i18n from 'locales/i18n';

class ToastHelper {
  static Mostrar = (
    titulo: string,
    mensagem: string,
    tipo?: VariantColor
  ): void => {
    store.dispatch(
      addToast({
        title: titulo,
        description: mensagem,
        color: tipo,
      })
    );
  };

  static Erro = (mensagem: string, titulo?: string): void => {
    ToastHelper.Mostrar(
      titulo || i18n.t('termos.erro'),
      mensagem,
      VariantColor.Danger
    );
  };

  static ErroGenerico = (): void => {
    ToastHelper.Erro(i18n.t('erros.tente-mais-tarde'));
  };

  static Sucesso = (mensagem: string, titulo?: string): void => {
    ToastHelper.Mostrar(
      titulo || i18n.t('termos.sucesso'),
      mensagem,
      VariantColor.Success
    );
  };

  static Alerta = (mensagem: string, titulo?: string): void => {
    ToastHelper.Mostrar(
      titulo || i18n.t('termos.atencao'),
      mensagem,
      VariantColor.Warning
    );
  };
}

export default ToastHelper;
