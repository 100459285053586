import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import usuarioService from 'services/usuario.service';
import { RootState } from 'store/store';

export interface UsuarioState {
  grupos: string[];
  nome: string;
  papeis: string[];
  politicaPrivacidadeAceita: boolean;
  usuario: string;
}
const initialState: UsuarioState = {
  grupos: [],
  nome: '',
  papeis: [],
  politicaPrivacidadeAceita: true,
  usuario: '',
};

const usuarioSlice = createSlice({
  name: 'usuario',
  initialState,
  reducers: {
    setUsuario: (state, action: PayloadAction<boolean>): void => {
      state.politicaPrivacidadeAceita = action.payload;
    },
  },
});

export const { setUsuario } = usuarioSlice.actions;

export const obterInformacao = createAsyncThunk(
  'usuario/obterInformacao',
  async (): Promise<any> => {
    const retorno = await usuarioService.obterPorRota('ïnformacao');

    return retorno;
  }
);

export const usuarioSelector = (state: RootState): UsuarioState =>
  state.usuario;

export default usuarioSlice.reducer;
