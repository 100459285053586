import FiltroPaginacao from 'models/filtro/FiltroPaginacao';
import FiltroResponse from 'models/filtro/FiltroResponse';
import Permissionamento from 'models/Permissionamento';
import GrupoPermissao from 'models/Shared/GrupoPermissao';
import BaseService from './base.service';

class GrupoPermissaoService extends BaseService<GrupoPermissao> {
  constructor() {
    super('grupopermissao');
  }

  listarPermissoesGrupoPorId = (
    id: number,
    filtro: FiltroPaginacao
  ): Promise<FiltroResponse<Permissionamento>> => {
    return super.filtrarPorParametrosSubRota(id, 'menu', filtro);
  };

  salvarPermissoesGrupo = (
    id: number,
    permissoes: Permissionamento[]
  ): Promise<void> => {
    return super.atualizarSubRota(id, 'menu', permissoes);
  };
}

export default new GrupoPermissaoService();
