import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'App';
import LoaderBar from 'components/LoaderBar/LoaderBar';
import setupInterceptors from 'services/setupInterceptors';
import { store } from 'store/store';
import reportWebVitals from 'reportWebVitals';
import 'locales/i18n';
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'scss/style.scss';

setupInterceptors();

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoaderBar />}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
