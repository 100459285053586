import classnames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';
import { VariantColor } from 'shared/VariantColor';
import { hostNameSelector } from 'store/hostName/hostName.slice';
import { TipoProjeto } from 'models/enums/TipoProjeto';
import { useAppSelector } from 'store/hooks';
import styles from './CustomButton.module.scss';

export interface CustomButtonProps {
  'aria-label'?: string | undefined;
  onClick?: () => void;
  style?: React.CSSProperties | undefined;
  type?: 'submit' | 'reset' | 'button' | undefined;
  color?: VariantColor | undefined;
  outline?: boolean | undefined;
  size?: string | undefined;
  disabled?: boolean | undefined;
  children?: React.ReactNode | undefined;
  className?: string | undefined;
  rightIcon?: string | undefined;
  leftIcon?: string | undefined;
  iconClassName?: string | undefined;
  id?: string;
  testId?: string;
}

const defaultProps = {
  color: VariantColor.Primary,
  testId: '',
};

const CustomButton: React.FC<CustomButtonProps> = (customButtonProps) => {
  const {
    children,
    className,
    rightIcon,
    leftIcon,
    iconClassName,
    id,
    testId,
    ...rest
  } = customButtonProps;

  const { hostName } = useAppSelector(hostNameSelector);

  return (
    <Button
      id={id}
      data-testid={`${testId}_CustomButton`}
      {...rest}
      className={classnames(
        hostName === TipoProjeto.MinhaTelmac ? styles.Telmac : styles.Rech,
        styles.CustomButton,
        className
      )}
    >
      {leftIcon && (
        <i className={classnames('mr-1', leftIcon, iconClassName)} />
      )}
      {children}
      {rightIcon && (
        <i className={classnames('ml-1', rightIcon, iconClassName)} />
      )}
    </Button>
  );
};

CustomButton.defaultProps = defaultProps;

export default React.memo(CustomButton);
