export const existemItens = (value: any[] | undefined): boolean => {
  return !!value && value.length > 0;
};

export const isNullOrUndefined = (value: any | undefined): boolean => {
  return value || value === false;
};

export const buscarEmpresa = (): string => {
  const hostName = window.location.hostname;
  return hostName.includes('telmac') ? 'telmac' : 'rech';
};
