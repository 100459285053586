import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomModalProps } from 'components/Core/CustomModal/CustomModal';
import { RootState } from 'store/store';

export interface ModalState {
  customModal: CustomModalProps;
}

const initialState: ModalState = {
  customModal: {},
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<any>): void => {
      state.customModal = {
        ...action.payload,
        isOpen: true,
      };
    },
    closeModal: (state) => {
      state.customModal = { ...state.customModal, isOpen: false };
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const selectModal = (state: RootState): CustomModalProps =>
  state.modal.customModal;

export default modalSlice.reducer;
