export enum EventoCategoria {
  Login = 'Efetuar Login',
  AtivacaoCadastro = 'Ativação de Cadastro',
  AtivacaoCadastroEfetuado = 'Cadastro Ativado',
  RecuperacaoSenhaEfetuada = 'Recuperação de senha efetuada',
  TrocaSenhaEfetuada = 'Troca de senha efetuada (interno)',
}

export enum EventoAcao {
  Redirecionar = 'Redirecionar',
  Download = 'Download',
  AtivacaoCadastro = 'Ativação de Cadastro',
  AtivadoCadastro = 'Cadastro Ativado',
  RecuperarSenha = 'Recuperar Senha',
  RecuperarSenhaEfetuada = 'Recuperação de Senha Efetuada',
  TrocarSenhaEfetuada = 'Troca de Senha Efetuada',
  TrocarSenha = 'Trocar Senha',
}

export enum EventoOrigem {
  TelaAlterarSenha = 'Alterar Senha',
  TelaRecuperarSenha = 'Recuperar Senha',
  TelaTrocarSenha = 'Trocar Senha',
}
