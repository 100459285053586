import LoaderBar from 'components/LoaderBar/LoaderBar';
import React, { lazy, Suspense } from 'react';

const EscolhaModuloPageLazy = lazy(() => import('./EscolhaModuloPage'));

const defaultProps = { children: '' };

const EscolhaModuloPage = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
): JSX.Element => (
  <Suspense fallback={<LoaderBar />}>
    <EscolhaModuloPageLazy {...props} />
  </Suspense>
);

EscolhaModuloPage.defaultProps = defaultProps;

export default EscolhaModuloPage;
