import classNames from 'classnames';
import CustomLabel from 'components/Core/CustomLabel/CustomLabel';
import { obterPrimeiraPalavra } from 'helpers/manipulacao.helper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutRoutes from 'routes/layout.routes';
import { useAppSelector } from 'store/hooks';
import { hostNameSelector } from 'store/hostName/hostName.slice';
import { sessaoSelector } from 'store/sessao/sessao.slice';

export interface IHeaderOutput {
  generateHeader: (location: string) => JSX.Element;
}

export const useHeader = (): IHeaderOutput => {
  const { t } = useTranslation();
  const { tokenSessao } = useAppSelector(sessaoSelector);
  const primeiroNome = tokenSessao && obterPrimeiraPalavra(tokenSessao.nome);
  const { hostName } = useAppSelector(hostNameSelector);
  const hostNamePrimeiraLetraMaiuscula = hostName
    ? hostName.charAt(0).toUpperCase() + hostName.slice(1)
    : '';
  const generateHeader = (location: string): JSX.Element => {
    switch (location) {
      case LayoutRoutes.auth.escolhaModulo:
        return (
          <>
            {t('auth:titulo-modulo', { usuario: primeiroNome })}

            <br />
            {t('auth:subtitulo-modulo')}
          </>
        );
      case LayoutRoutes.auth.trocarSenha:
        return (
          <>
            {t('auth:titulo-bem-vindo', {
              modulo: hostNamePrimeiraLetraMaiuscula,
            })}
          </>
        );
      default:
        return (
          <>
            {t('auth:titulo', {
              modulo: hostNamePrimeiraLetraMaiuscula,
            })}
          </>
        );
    }
  };

  return { generateHeader };
};
