import jwtDecode from 'jwt-decode';
import TokenSessao from 'models/sessao/TokenSessao';
import TokenDecode from 'models/sessao/TokenDecode';
import TokenEncode from 'models/sessao/TokenEncode';
import ModuloService from 'services/modulo.service';
import Sessao from 'models/sessao/Sessao';
import i18n from 'locales/i18n';
import LocalStorageService from './localStorage.service';

const CHAVE_SESSAO = 'nsr_sessao';

class SessaoService {
  estaLogadoValidarSessao = (): boolean => {
    const existeSessao = LocalStorageService.existe(CHAVE_SESSAO);

    if (existeSessao) {
      const sessao = LocalStorageService.obter(CHAVE_SESSAO);
      const expiracaoSegundos = Number(sessao.tokenSessao?.expiracao);

      if (Number.isNaN(expiracaoSegundos)) {
        this.removerSessao();
        ModuloService.removerModulo();

        return false;
      }

      const expiracao = expiracaoSegundos * 1000;

      if (expiracao < Date.now()) {
        this.removerSessao();
        ModuloService.removerModulo();

        return false;
      }
    }

    return existeSessao;
  };

  obterSessao = (): Sessao => {
    const sessao = LocalStorageService.obter(CHAVE_SESSAO);

    return sessao;
  };

  salvarSessao = (sessao: Sessao): void => {
    LocalStorageService.salvar(CHAVE_SESSAO, sessao);
  };

  removerSessao = (): void => {
    LocalStorageService.remover(CHAVE_SESSAO);
  };

  criarTokenSessao = (token: TokenEncode): TokenSessao => {
    const tokenDecodificado = jwtDecode<TokenDecode>(token.tokenAcesso);
    const CHAVE_PERMISSOES = tokenDecodificado.resource_access;

    if (CHAVE_PERMISSOES) {
      const sessao: TokenSessao = {
        tokenAcesso: token.tokenAcesso,
        tokenAtualizacao: token.tokenAtualizacao,
        email: tokenDecodificado.email,
        nome: tokenDecodificado.name,
        usuario: tokenDecodificado.preferred_username,
        criacao: tokenDecodificado.iat,
        expiracao: tokenDecodificado.exp,
        grupos: tokenDecodificado.groups || [],
        permissoes: CHAVE_PERMISSOES.nsr?.roles || [],
        acesso: tokenDecodificado.resource_access || [],
        externo: tokenDecodificado.externo || false,
      };

      return sessao;
    }

    throw new Error(i18n.t('erros.permissao'));
  };
}

export default new SessaoService();
