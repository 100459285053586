import { RotaTemplate } from 'models/RotaTemplate';
import LoginPage from 'pages/Auth/LoginPage/LoginPage.lazy';
import EscolhaModuloPage from 'pages/Auth/EscolhaModuloPage/EscolhaModuloPage.lazy';
import RecuperarSenhaPage from 'pages/Auth/RecuperarSenhaPage/RecuperarSenhaPage.lazy';
import LayoutRoutes from 'routes/layout.routes';
import AtivarCadastroPage from 'pages/Auth/AtivarCadastroPage/AtivarCadastroPage.lazy';
import AlterarSenhaPage from 'pages/Auth/AlterarSenhaPage/AlterarSenhaPage.lazy';
import TrocarSenhaPage from 'pages/Auth/TrocarSenhaPage/TrocarSenhaPage.lazy';
import TermosEPoliticaPage from 'pages/Auth/TermosEPoliticaPage/TermosEPoliticaPage.lazy';
import ExcessoEstoquePage from 'pages/Auth/ExcessoEstoquePage/ExcessoEstoquePage.lazy';

const { layout } = LayoutRoutes.auth;

export default [
  new RotaTemplate(LayoutRoutes.auth.login, 'Login', layout, LoginPage),
  new RotaTemplate(
    LayoutRoutes.auth.recuperarSenha,
    'Esqueci minha Senha',
    layout,
    RecuperarSenhaPage
  ),
  new RotaTemplate(
    LayoutRoutes.auth.escolhaModulo,
    'Escolha do Módulo',
    layout,
    EscolhaModuloPage
  ),
  new RotaTemplate(
    LayoutRoutes.auth.primeiroAcesso,
    'Primeiro Acesso',
    layout,
    AtivarCadastroPage
  ),
  new RotaTemplate(
    LayoutRoutes.auth.alterarSenha,
    'Alterar Senha',
    layout,
    AlterarSenhaPage
  ),
  new RotaTemplate(
    LayoutRoutes.auth.trocarSenha,
    'Troca de Senha',
    layout,
    TrocarSenhaPage
  ),
  new RotaTemplate(
    LayoutRoutes.auth.termosPolitica,
    'Termos e Politicas de Uso',
    layout,
    TermosEPoliticaPage
  ),
  new RotaTemplate(
    LayoutRoutes.auth.excessoEstoque,
    'Excessos de Estoque',
    layout,
    ExcessoEstoquePage
  ),
];
