import classNames from 'classnames';
import { TipoProjeto } from 'models/enums/TipoProjeto';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col, Nav, NavItem, NavLink, Container } from 'reactstrap';
import LayoutRoutes from 'routes/layout.routes';
import { Footer } from 'shared/icons';
import { useAppSelector } from 'store/hooks';
import { hostNameSelector } from 'store/hostName/hostName.slice';
import { parametroSelector } from 'store/parametro/parametro.slice';
import styles from './CustomFooter.module.scss';

interface CustomFooterProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  auth?: boolean | undefined;
}

const CustomFooter: React.FC<CustomFooterProps> = (customFooterProps: any) => {
  const { className, ...res } = customFooterProps;
  const { hostName } = useAppSelector(hostNameSelector);
  const { t } = useTranslation();
  const { empresa } = useAppSelector(parametroSelector);
  const history = useHistory();
  const getBackgroundImage = (hostname: string): string => {
    switch (hostname) {
      case TipoProjeto.MinhaRech:
        return `url(${Footer})`;

      case TipoProjeto.MinhaTelmac:
        return '';

      default:
        return `url(${Footer})`;
    }
  };

  const enviarParaTermosEPolitica = (): void => {
    history.push(LayoutRoutes.auth.termosPolitica);
  };

  const itens = [
    {
      texto: t('footer:ajuda'),
      link: empresa?.urlAjuda,
      testId: 'ajuda',
    },
    {
      texto: t('footer:sobre'),
      link: empresa?.urlSobreNos,
      testId: 'sobre',
    },
    {
      texto: t('footer:faq'),
      link: empresa?.urlFaq,
      testId: 'faq',
    },
  ];

  return (
    <footer
      className={classNames(
        'py-3  container position-absolute justify-content-end',
        hostName === TipoProjeto.MinhaTelmac ? styles.Telmac : styles.Rech,
        styles.CustomFooter,
        className
      )}
      data-testid="CustomFooter"
      style={{
        backgroundImage: getBackgroundImage(hostName!),
      }}
      {...res}
    >
      <Container>
        <Row className="align-items-center justify-content-xl-between m-0">
          <Col md="4" xs="3" className="pl-md-4">
            <div
              className={classNames(
                `copyright text-left text-gray600 text-nowrap ${hostName}`,
                styles.CompreOnline
              )}
            >
              <a
                data-testid="linkRechFooter"
                className="ml-1"
                href={empresa?.urlEcommerce}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('compre-online')}
              </a>
            </div>
          </Col>

          <Col md="8" xs="9" className="pr-md-4 ">
            <Nav
              className={classNames(
                'nav-footer justify-content-end pr-0 justify-content-md-end m-0'
              )}
            >
              {empresa?.possuiPoliticaVigente && (
                <NavItem key="custom-footer-item-termo-de-uso">
                  <NavLink
                    data-testid="termo-de-usoFooter"
                    onClick={enviarParaTermosEPolitica}
                    rel="noopener noreferrer"
                    className={styles.FooterNavItem}
                  >
                    {t('footer:termoDeUso')}
                  </NavLink>
                </NavItem>
              )}

              {itens.map((item, index) => (
                <NavItem key={`custom-footer-item-${index}`}>
                  <NavLink
                    data-testid={`${item.testId}Footer`}
                    href={item.link}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.FooterNavItem}
                  >
                    {item.texto}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default React.memo(CustomFooter);
