import classnames from 'classnames';
import React from 'react';
import { Label, LabelProps } from 'reactstrap';
import styles from './CustomLabel.module.scss';

export enum TypeLabel {
  regular,
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  d1 = 'display-1',
  d2 = 'display-2',
  d3 = 'display-3',
  d4 = 'display-4',
}

interface CustomLabelProps extends LabelProps {
  type?: TypeLabel;
  bold?: boolean | undefined;
  italic?: boolean | undefined;
  normalFont?: boolean | undefined;
  light?: boolean | undefined;
  testId?: string;
}

const CustomLabel: React.FC<CustomLabelProps> = (customLabelProps) => {
  const {
    children,
    className,
    type,
    bold,
    italic,
    normalFont,
    light,
    testId,
    ...rest
  } = customLabelProps;

  const parentTestId = testId || '';

  const getHeadingTemplate = (
    fontWeight: string | undefined,
    tag: React.ElementType
  ): JSX.Element | null => {
    return (
      <Label
        data-testid={`${parentTestId}_CustomLabel`}
        tag={tag}
        {...rest}
        className={classnames(
          !bold && !normalFont && fontWeight,
          {
            'font-weight-normal': !bold && normalFont,
            'font-italic': italic,
          },
          styles.CustomLabel,
          className
        )}
      >
        {children}
      </Label>
    );
  };

  const getLabelTemplate = (): JSX.Element | null => {
    switch (type) {
      case TypeLabel.d1:
      case TypeLabel.d2:
      case TypeLabel.d3:
      case TypeLabel.d4:
        return (
          <Label
            data-testid={`${parentTestId}_CustomLabel`}
            tag="h1"
            {...rest}
            className={classnames(
              type,
              {
                'font-weight-light': (!bold && !normalFont) || light,
                'font-weight-normal': !bold && normalFont,
                'font-italic': italic,
              },
              styles.CustomLabel,
              className
            )}
          >
            {children}
          </Label>
        );

      case TypeLabel.h1:
      case TypeLabel.h2:
      case TypeLabel.h3:
      case TypeLabel.h4:
        return getHeadingTemplate('font-weight-light', type);
      case TypeLabel.h5:
        return getHeadingTemplate('font-weight-normal', type);
      case TypeLabel.h6:
        return getHeadingTemplate('font-weight-500', type);

      case TypeLabel.regular:
      default:
        return (
          <Label
            data-testid={`${parentTestId}_CustomLabel`}
            {...rest}
            className={classnames(
              styles.CustomLabel,
              {
                'font-weight-bold': bold,
                'font-italic': italic,
                'font-weight-light': light,
              },
              className
            )}
          >
            {children}
          </Label>
        );
    }
  };

  return getLabelTemplate();
};

export default React.memo(CustomLabel);
