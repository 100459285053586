import cadastroProjeto from 'locales/pt-BR/dnp/cadastro-projeto.json';
import listaProjeto from 'locales/pt-BR/dnp/lista-projeto.json';
import filtroAvancadoProjeto from 'locales/pt-BR/dnp/filtro-avancado-projeto.json';
import cadastrarProjeto from 'locales/pt-BR/dnp/etapas/cadastrar-projeto.json';
import cadastrarFornecedor from 'locales/pt-BR/dnp/etapas/cadastrar-fornecedor.json';
import preCadastrarProduto from 'locales/pt-BR/dnp/etapas/pre-cadastrar-produto.json';
import parecerJuridicoRegistrar from 'locales/pt-BR/dnp/etapas/parecer-juridico-registrar.json';
import acionarTimeFiscal from 'locales/pt-BR/dnp/etapas/acionar-time-fiscal.json';
import parecerJuridico from 'locales/pt-BR/dnp/etapas/parecer-juridico.json';
import comprarAmostra from 'locales/pt-BR/dnp/etapas/comprar-amostra.json';
import analisarAmostra from 'locales/pt-BR/dnp/etapas/analisar-amostra.json';
import solicitarRepackingRegistrar from 'locales/pt-BR/dnp/etapas/solicitar-repacking-registrar.json';
import solicitarRepacking from 'locales/pt-BR/dnp/etapas/solicitar-repacking.json';
import analisarAmostraRegistrar from 'locales/pt-BR/dnp/etapas/analisar-amostra-registrar.json';
import sugerirPrecoVenda from 'locales/pt-BR/dnp/etapas/sugerir-preco-venda.json';
import sugerirPrecoVendaRegistrar from 'locales/pt-BR/dnp/etapas/sugerir-preco-venda-registrar.json';
import sugerirEnxoval from 'locales/pt-BR/dnp/etapas/sugerir-enxoval.json';
import definicaoEnxoval from 'locales/pt-BR/dnp/definicao-enxoval.json';
import comprarLote from 'locales/pt-BR/dnp/etapas/comprar-lote.json';
import avaliarLote from 'locales/pt-BR/dnp/etapas/avaliar-lote.json';
import avaliarLoteRegistrar from 'locales/pt-BR/dnp/etapas/avaliar-lote-registrar.json';
import criarInstrucaoTrabalho from 'locales/pt-BR/dnp/etapas/criar-instrucao-trabalho.json';
import criarInstrucaoTrabalhoLogistica from 'locales/pt-BR/dnp/etapas/criar-instrucao-trabalho-logistica.json';
import criarInstrucaoTrabalhoSuporte from 'locales/pt-BR/dnp/etapas/criar-instrucao-trabalho-suporte.json';
import acompanharProduto from 'locales/pt-BR/dnp/etapas/acompanhar-produto.json';
import informarCodigoVenda from 'locales/pt-BR/dnp/etapas/informar-codigo-venda.json';
import analiseGiro from 'locales/pt-BR/dnp/analise-giro.json';
import pipelineDeProdutos from 'locales/pt-BR/dnp/acompanhamentoProduto/pipeline-de-produtos.json';
import filtroAvancadoProduto from 'locales/pt-BR/dnp/acompanhamentoProduto/filtro-avancado-produto.json';
import produtoDetalhes from 'locales/pt-BR/dnp/acompanhamentoProduto/produto-detalhes.json';
import cadastroLote from 'locales/pt-BR/dnp/cadastro-lote.json';
import controleRelatorio from 'locales/pt-BR/dnp/controle-relatorio.json';
import listaPedidoCompra from 'locales/pt-BR/dnp/lista-pedido-compra.json';
import filtroAvancadoPedidoCompra from 'locales/pt-BR/dnp/filtro-avancado-pedido-compra.json';
import controleRelatorioMarketing from 'locales/pt-BR/dnp/acompanhamentoProduto/controle-relatorio-marketing.json';
import controleRelatorioPricing from 'locales/pt-BR/dnp/acompanhamentoProduto/controle-relatorio-pricing.json';

export default {
  cadastroProjeto,
  listaProjeto,
  filtroAvancadoProjeto,
  cadastrarProjeto,
  cadastrarFornecedor,
  preCadastrarProduto,
  parecerJuridicoRegistrar,
  acionarTimeFiscal,
  parecerJuridico,
  comprarAmostra,
  analisarAmostra,
  solicitarRepackingRegistrar,
  solicitarRepacking,
  analisarAmostraRegistrar,
  informarCodigoVenda,
  sugerirPrecoVenda,
  sugerirPrecoVendaRegistrar,
  sugerirEnxoval,
  comprarLote,
  avaliarLote,
  avaliarLoteRegistrar,
  criarInstrucaoTrabalho,
  criarInstrucaoTrabalhoLogistica,
  criarInstrucaoTrabalhoSuporte,
  acompanharProduto,
  analiseGiro,
  definicaoEnxoval,
  pipelineDeProdutos,
  filtroAvancadoProduto,
  produtoDetalhes,
  cadastroLote,
  controleRelatorio,
  listaPedidoCompra,
  filtroAvancadoPedidoCompra,
  controleRelatorioMarketing,
  controleRelatorioPricing,
};
