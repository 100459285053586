import { TipoAmbienteGA } from 'models/enums/TipoAmbienteGA';

interface TipoConfig {
  apiUrl: string;
  PortalApiUrl: string;
  gaAmbiente: TipoAmbienteGA;
}

const configSemDns = {
  dev: {
    apiUrl: 'http://10.99.220.68:5010/v1/',
    PortalApiUrl: 'http://10.99.220.68:5090/v1/',
    gaAmbiente: TipoAmbienteGA.Desenvolvimento,
  },
  tst: {
    apiUrl: 'http://10.10.3.2:5011/v1/',
    PortalApiUrl: 'http://10.10.3.2:5091/v1/',
    gaAmbiente: TipoAmbienteGA.Teste,
  },
  hml: {
    apiUrl: 'http://10.10.3.2:5010/v1/',
    PortalApiUrl: 'http://10.10.3.2:5090/v1/',
    gaAmbiente: TipoAmbienteGA.Homologacao,
  },
};

const configRech = {
  local: {
    apiUrl: 'http://minharech-local.agrocompetence.com:5010/v1/',
    PortalApiUrl: 'http://minharech-local.agrocompetence.com:5090/v1/',
    gaAmbiente: TipoAmbienteGA.Desenvolvimento,
  },
  dev: {
    apiUrl: 'http://minharech-dev.agrocompetence.com:5010/v1/',
    PortalApiUrl: 'http://minharech-dev.agrocompetence.com:5090/v1/',
    gaAmbiente: TipoAmbienteGA.Desenvolvimento,
  },
  tst: {
    apiUrl: 'https://minharech-tst.agrocompetence.com/auth-api/v1/',
    PortalApiUrl:
      'https://minharech-tst.agrocompetence.com/portal-cliente-api/v1/',
    gaAmbiente: TipoAmbienteGA.Teste,
  },
  hml: {
    apiUrl: 'https://minharech-hml.agrocompetence.com/auth-api/v1/',
    PortalApiUrl:
      'https://minharech-hml.agrocompetence.com/portal-cliente-api/v1/',
    gaAmbiente: TipoAmbienteGA.Homologacao,
  },
  prd: {
    apiUrl: 'https://minharech.agrocompetence.com/auth-api/v1/',
    PortalApiUrl: 'https://minharech.agrocompetence.com/portal-cliente-api/v1/',
    gaAmbiente: TipoAmbienteGA.Producao,
  },
  default: {
    apiUrl: 'https://minharech-hml.agrocompetence.com/auth-api/v1/',
    PortalApiUrl:
      'https://minharech-hml.agrocompetence.com/portal-cliente-api/v1/',
    gaAmbiente: TipoAmbienteGA.Homologacao,
  },
};

const configTelmac = {
  local: {
    apiUrl: 'http://minhatelmac-local.agrocompetence.com:5010/v1/',
    PortalApiUrl: 'http://minhatelmac-local.agrocompetence.com:5090/v1/',
    gaAmbiente: TipoAmbienteGA.Desenvolvimento,
  },
  dev: {
    apiUrl: 'http://minhatelmac-dev.agrocompetence.com:5010/v1/',
    PortalApiUrl: 'http://minhatelmac-dev.agrocompetence.com:5090/v1/',
    gaAmbiente: TipoAmbienteGA.Desenvolvimento,
  },
  tst: {
    apiUrl: 'https://minhatelmac-tst.agrocompetence.com/auth-api/v1/',
    PortalApiUrl:
      'https://minhatelmac-tst.agrocompetence.com/portal-cliente-api/v1/',
    gaAmbiente: TipoAmbienteGA.Teste,
  },
  hml: {
    apiUrl: 'https://minhatelmac-hml.agrocompetence.com/auth-api/v1/',
    PortalApiUrl:
      'https://minhatelmac-hml.agrocompetence.com/portal-cliente-api/v1/',
    gaAmbiente: TipoAmbienteGA.Homologacao,
  },
  prd: {
    apiUrl: 'https://minhatelmac.agrocompetence.com/auth-api/v1/',
    PortalApiUrl:
      'https://minhatelmac.agrocompetence.com/portal-cliente-api/v1/',
    gaAmbiente: TipoAmbienteGA.Producao,
  },
  default: {
    apiUrl: 'https://minhatelmac-hml.agrocompetence.com/auth-api/v1/',
    PortalApiUrl:
      'https://minhatelmac-hml.agrocompetence.com/portal-cliente-api/v1/',
    gaAmbiente: TipoAmbienteGA.Homologacao,
  },
};

const { hostname, port } = window.location;

let configEnv: TipoConfig = configRech.default;

switch (hostname) {
  case 'localhost':
    configEnv = { ...configRech.default, ...configSemDns.dev };
    break;
  case '10.99.220.68':
    configEnv = { ...configRech.default, ...configSemDns.dev };
    break;
  case '10.10.3.2':
    if (port === '3011') {
      configEnv = { ...configRech.default, ...configSemDns.tst };
      break;
    }

    configEnv = { ...configRech.default, ...configSemDns.hml };
    break;
  case 'minharech-dev.agrocompetence.com':
  case 'minharech-local.agrocompetence.com':
    configEnv = { ...configRech.default, ...configRech.dev };
    break;
  case 'minharech-tst.agrocompetence.com':
    configEnv = { ...configRech.default, ...configRech.tst };
    break;
  case 'minharech-hml.agrocompetence.com':
    configEnv = { ...configRech.default, ...configRech.hml };
    break;
  case 'minharech.agrocompetence.com':
  case '10.10.2.81':
    configEnv = { ...configRech.default, ...configRech.prd };
    break;

  case 'minhatelmac-dev.agrocompetence.com':
  case 'minhatelmac-local.agrocompetence.com':
    configEnv = { ...configTelmac.default, ...configTelmac.dev };
    break;
  case 'minhatelmac-tst.agrocompetence.com':
    configEnv = { ...configTelmac.default, ...configTelmac.tst };
    break;
  case 'minhatelmac-hml.agrocompetence.com':
    configEnv = { ...configTelmac.default, ...configTelmac.hml };
    break;
  case 'minhatelmac.agrocompetence.com':
    configEnv = { ...configTelmac.default, ...configTelmac.prd };
    break;
  default:
    break;
}

export default configEnv;
