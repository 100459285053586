import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import ptBR from './i18n.ptBR';
// import en from './i18n.en';

const resources = {
  'pt-BR': ptBR,
  // en,
};

const optionsInit = {
  resources,
  // Set default namespace
  defaultNS: 'global',
  // default/fallback language
  fallbackLng: 'pt-BR',
  // debug: true,
  // detects and caches a cookie from the language provided
  detection: {
    order: ['queryString', 'cookie'],
    cache: ['cookie'],
  },
  interpolation: {
    escapeValue: false,
  },
};

i18n.on('languageChanged', (lng): void => {
  moment.locale(lng);
});

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(optionsInit);

export default i18n;
