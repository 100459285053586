/* eslint-disable no-underscore-dangle */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import configEnv from 'config.env';
import React from 'react';
import sessaoService from 'services/sessao.service';

const { useState, useMemo, useEffect } = React;

export const api = axios.create({
  baseURL: configEnv.apiUrl,
});

const customAxios = (dynamicBaseURL: string): any => {
  const axiosInstance = axios.create({
    baseURL: dynamicBaseURL,
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const sessao = sessaoService.obterSessao();

      if (sessao && sessao.tokenSessao && !!sessao.tokenSessao.tokenAcesso) {
        config.headers.Authorization = `Bearer ${sessao.tokenSessao?.tokenAcesso}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== 'autenticacao') {
        // Token de acesso expirado
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 403) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          try {
            return api(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        } else if (err.response && err.response.status === 400) {
          // Rota dinamica existe mas :idProduto nao existe na base de dados
        }
      }

      return Promise.reject(err);
    }
  );
  return axiosInstance;
};

export default customAxios;

export const useAxiosLoader = (): boolean[] => {
  const [loadCounter, setLoadCounter] = useState(0);

  const interceptors = useMemo(() => {
    const inc = (): void => setLoadCounter((c) => c + 1);
    const dec = (): void => setLoadCounter((c) => c - 1);

    return {
      request: (config: AxiosRequestConfig) => {
        inc();
        return config;
      },
      response: (response: AxiosResponse<any>) => {
        dec();
        return response;
      },
      error: (error: any) => {
        dec();
        return Promise.reject(error);
      },
    };
  }, []);

  useEffect(() => {
    const reqInterceptor = api.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );

    const resInterceptor = api.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
    return () => {
      api.interceptors.request.eject(reqInterceptor);
      api.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  return [loadCounter > 0];
};
