import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import SessaoService from 'services/sessao.service';
import ModuloService from 'services/modulo.service';
import TokenEncode from 'models/sessao/TokenEncode';
import TokenSessao from 'models/sessao/TokenSessao';
import Sessao from 'models/sessao/Sessao';
import ModuloSessao from 'models/modulo/ModuloSessao';
import Modulo from 'shared/modulos';
import { buscarEmpresa } from 'helpers/validacao.helper';

export interface SessaoState {
  tokenSessao: TokenSessao | null;
  lembrarSessao: boolean;
  sessaoCarregada: boolean;
  moduloSessao: ModuloSessao;
}

const initialState: SessaoState = {
  tokenSessao: null,
  lembrarSessao: false,
  sessaoCarregada: false,
  moduloSessao: {
    moduloAtivo: {
      nome: '',
      descricao: '',
      identificador: '',
    },
    moduloEscolhido: false,
    moduloExterno: false,
  },
};

const sessaoSlice = createSlice({
  name: 'sessao',
  initialState,
  reducers: {
    atualizarSessao: (
      state,
      action: PayloadAction<{ token: TokenEncode; lembrarSessao: boolean }>
    ) => {
      const sessao: Sessao = {
        tokenSessao: SessaoService.criarTokenSessao(action.payload.token),
        lembrarSessao: action.payload.lembrarSessao,
      };
      state.tokenSessao = sessao.tokenSessao;
      state.lembrarSessao = sessao.lembrarSessao;
      state.sessaoCarregada = true;
      SessaoService.salvarSessao(sessao);
    },
    recarregarSessao: (state) => {
      if (SessaoService.estaLogadoValidarSessao()) {
        const sessao = SessaoService.obterSessao();
        state.tokenSessao = sessao?.tokenSessao;
        state.lembrarSessao = sessao?.lembrarSessao;
        state.sessaoCarregada = true;
      }
    },
    limparSessao: (state) => {
      state.tokenSessao = initialState.tokenSessao;
      state.lembrarSessao = initialState.lembrarSessao;
      state.sessaoCarregada = initialState.sessaoCarregada;
      state.moduloSessao = initialState.moduloSessao;
      ModuloService.removerModulo();
      SessaoService.removerSessao();
    },
    limparSessaoTokenExpirado: (state) => {
      state.tokenSessao = initialState.tokenSessao;
      state.lembrarSessao = initialState.lembrarSessao;
      state.sessaoCarregada = initialState.sessaoCarregada;
    },
    atualizarModulo: (state, action: PayloadAction<any>) => {
      const moduloSessao: ModuloSessao = {
        moduloAtivo: action.payload,
        moduloEscolhido: true,
      };
      state.moduloSessao.moduloAtivo = action.payload;
      state.moduloSessao.moduloEscolhido = true;
      ModuloService.salvarModulo(moduloSessao);
    },
    atualizarModuloExterno: (state) => {
      state.moduloSessao.moduloExterno = false;
      ModuloService.salvarModulo({
        ...state.moduloSessao,
        moduloExterno: false,
      });
    },
    recarregarModulo: (state) => {
      if (ModuloService.estaAtivoModulo()) {
        const modulo = ModuloService.obterModulo();
        state.moduloSessao.moduloAtivo = modulo?.moduloAtivo;
        state.moduloSessao.moduloEscolhido = true;
        state.moduloSessao.moduloExterno = modulo?.moduloExterno ?? false;
      }
    },
    limparModulo: (state) => {
      state.moduloSessao.moduloAtivo = initialState.moduloSessao.moduloAtivo;
      state.moduloSessao.moduloExterno =
        initialState.moduloSessao.moduloExterno;
      state.moduloSessao.moduloEscolhido =
        initialState.moduloSessao.moduloEscolhido;
      ModuloService.removerModulo();
    },
    verificarModulos: (state) => {
      const empresa = buscarEmpresa();
      const permissoes = state.tokenSessao?.grupos;
      const modulos = new Set();
      const moduloExterno = ModuloService.obterModulo();
      permissoes?.forEach((grupo: string) => {
        if (state.tokenSessao?.externo) modulos.add(grupo.split('/')[1]);

        if (grupo.split('/')[1] === empresa)
          modulos.add(grupo.slice(grupo.indexOf(`${empresa}/`)).split('/')[1]);
      });
      state.moduloSessao.quantidadeDeModulos = modulos.size;
      const moduloObj = Object.values(Modulo);

      if (
        (modulos.has('portal-cliente') && state.tokenSessao?.externo) ||
        (modulos.size === 1 &&
          !state.tokenSessao?.externo &&
          !modulos.has('administrador'))
      ) {
        const moduloUnico = moduloObj.find((modulo) =>
          modulos.has(modulo.identificador)
        );
        state.moduloSessao.moduloAtivo =
          moduloUnico || initialState.moduloSessao.moduloAtivo;
        const moduloSessao: ModuloSessao = {
          moduloAtivo: state.moduloSessao.moduloAtivo,
          moduloEscolhido: true,
          moduloExterno: moduloExterno?.moduloExterno ?? false,
        };
        ModuloService.salvarModulo(moduloSessao);
      }
    },
  },
});

export const {
  atualizarSessao,
  recarregarSessao,
  limparSessao,
  limparSessaoTokenExpirado,
  atualizarModulo,
  recarregarModulo,
  limparModulo,
  verificarModulos,
  atualizarModuloExterno,
} = sessaoSlice.actions;

export const sessaoSelector = (state: RootState): SessaoState => state.sessao;
export const moduloSelector = (state: RootState): string =>
  state.sessao.moduloSessao.moduloAtivo.identificador;
export const quantidadeModuloSelector = (
  state: RootState
): number | undefined => state.sessao.moduloSessao.quantidadeDeModulos;
export const permissoesSelector = (state: RootState): string[] => {
  if (state.sessao.tokenSessao) {
    return state.sessao.tokenSessao?.permissoes;
  }
  return [];
};

export const gruposSelector = (state: RootState): string[] => {
  if (state.sessao.tokenSessao) {
    return state.sessao.tokenSessao?.grupos;
  }
  return [];
};
export const moduloExterno = (state: RootState): boolean | undefined =>
  state.sessao.moduloSessao.moduloExterno;
export default sessaoSlice.reducer;
